import React from "react"
import styled from "@emotion/styled"
import { Caption, Heading } from "kui-basic"
import { observer } from "mobx-react"
import { CopyButton } from "kui-crm"
import Card from "../../../../../../components/common/Card"
import useApartmentStore from "../../../../../../hooks/useApartmentStore"

const ApartmentAddressCard = () => {
  const { apartmentInfo } = useApartmentStore()

  return (
    <StyledCard>
      <div>
        <Heading size="h2">{apartmentInfo?.address}</Heading>
        <Caption size="xs" color="fiftyP">
          {apartmentInfo?.folderNumber}
        </Caption>
      </div>
      <CopyButton text={apartmentInfo?.address || ""} />
    </StyledCard>
  )
}

export default observer(ApartmentAddressCard)

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
