import { InspectionFeedbackParams } from "kui-crm"
import inspectionFeedbackLabels from "../../../ApartmentPage/common/InspectionsList/InspectionFeedbackStatus/content"

const getStatusLabel = (
  postfix: string,
  feedback: InspectionFeedbackParams | null,
) => {
  if (!feedback) return ""

  return `${inspectionFeedbackLabels[feedback.status]} ${postfix}`
}

export default getStatusLabel
