import React from "react"
import { useWatch } from "react-hook-form"
import FieldsHeading from "../../../../../../components/common/FieldsHeading"
import MeterFields from "../MeterFields"
import { getMeters } from "../content"
import { MetersFieldsProps } from "./types"

const MetersFields = (props: MetersFieldsProps) => {
  const { form, disabled } = props
  const maxMeters = useWatch({
    control: form.control,
    name: "maxMeters",
  })
  const meters = getMeters(maxMeters)

  return (
    <>
      <FieldsHeading title="Счетчики" />
      {meters.map((resource, index) => (
        <MeterFields
          key={index}
          resource={resource}
          index={index}
          form={form}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default MetersFields
