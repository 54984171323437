import { ApartmentParams } from "../../../../../../types/store/apartments"

type ApartmentInfoFieldParams = {
  label: string
  value?: string | number | null
}

const getApartmentInfoFields = (
  apartmentInfo: ApartmentParams | null,
): ApartmentInfoFieldParams[] => [
  { label: "Подъезд", value: apartmentInfo?.entrance },
  { label: "Код домофона", value: apartmentInfo?.intercomCode },
  { label: "Этаж", value: apartmentInfo?.floor },
  {
    label: apartmentInfo?.tenant ? "Жилец" : "Собственник",
    value: apartmentInfo?.tenant?.name || apartmentInfo?.landlord?.name,
  },
]

export default getApartmentInfoFields
