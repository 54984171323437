import React from "react"
import { InspectionFeedback } from "kui-crm_actions"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import getStatusLabel from "./content"
import useInspectionStore from "../../store"

const InspectionFeedbackBlock = () => {
  const { tenantFeedback, landlordFeedback, resignInspection } =
    useInspectionStore()

  const tenantLabel = getStatusLabel("жильцом", tenantFeedback)
  const landlordLabel = getStatusLabel("собственником", landlordFeedback)

  return (
    <Box mb={2}>
      <InspectionFeedback
        tenantFeedback={tenantFeedback}
        landlordFeedback={landlordFeedback}
        resignInspection={resignInspection}
        resendLabel="Отправить на переподпись"
        tenantLabel={tenantLabel}
        landlordLabel={landlordLabel}
      />
    </Box>
  )
}

export default observer(InspectionFeedbackBlock)
