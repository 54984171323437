import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { DateWithDotProps } from "./types"

const DateWithDot = (props: DateWithDotProps) => {
  const { date, withDot } = props

  if (!date) return null

  return (
    <StyledWrapper container alignItems="center" wrap="nowrap">
      <Caption size="xs" color="fiftyP">
        {date?.toFormat("dd.MM.yyyy")}
      </Caption>
      {withDot && <StyledDot />}
    </StyledWrapper>
  )
}

export default DateWithDot

const StyledWrapper = styled(Grid)`
  width: fit-content;
`

const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 4px;
  background: ${({ theme, color }) =>
    color === "red" ? theme.palette.red.fiftyP : theme.palette.brand.main};
`
