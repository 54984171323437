import React from "react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { getInventoryFields } from "./content"
import MetersFields from "../../../../pages/ApartmentPage/variants/InventoryInspectionForm/FullInventoryInfoStep/MetersFields"
import FillingGroupsFields from "../../../../pages/ApartmentPage/variants/InventoryInspectionForm/FullInventoryInfoStep/FillingGroupsFields"
import { InspectionFieldsProps } from "../types"

const InventoryInspectionFields = (props: InspectionFieldsProps) => {
  const { form, disabled } = props
  const fields = getInventoryFields()

  return (
    <Grid container spacing={2}>
      {fields.map(({ size, ...field }) => (
        <Grid item xs={12} key={field.name}>
          <InputByType disabled={disabled} form={form} {...field} />
        </Grid>
      ))}
      <MetersFields disabled={disabled} form={form} />
      <FillingGroupsFields disabled={disabled} form={form} />
    </Grid>
  )
}

export default InventoryInspectionFields
