import React, { useState } from "react"
import { AppBar, Button, theme, ToolBar } from "kui-basic"
import styled from "@emotion/styled"
import { useNavigate } from "react-router"
import { MaroomIcon } from "kui-icon"
import HideMenuContent from "./HideMenuContent"
import { headerRoutes } from "../../../routes/headerRoutes"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const goToMain = () => {
    navigate("/")
  }

  return (
    <StyledAppBar>
      <StyledToolBar>
        <HideMenuContent
          links={headerRoutes}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <StyledButton size="xs" variant="transparent" onClick={goToMain}>
          <MaroomIcon width={88} height={20} />
        </StyledButton>
      </StyledToolBar>
    </StyledAppBar>
  )
}

export default Header

const StyledButton = styled(Button)`
  padding: 0 !important;
  margin-left: calc(50% - 32px - 45px);
  svg {
    path {
      fill: ${theme.palette.brand.main};
    }
  }
`

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  z-index: 1000;
  background: white;
  border-bottom: 1px solid ${theme.palette.background.light3};
`

const StyledToolBar = styled(ToolBar)`
  width: 100%;
  padding: 0 16px;
`
