import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, Paginator } from "kui-utils"
import { ApartmentInspectionListModel } from "kui-crm"
import InspectionsStore from "./InspectionsStore"
import { InspectionRowParams } from "../../types/store/inspections"
import { ContractLinkParams } from "../../types/common"
import ApartmentAgent from "../../agent/ApartmentAgent"
import { GetApartmentInspectionsResponse } from "../../types/api/inspections"

class ContractInspectionsStore {
  loader: Loader

  paginator: Paginator

  inspections: InspectionRowParams[]

  contract: ContractLinkParams

  constructor(contract: ContractLinkParams) {
    this.loader = new Loader()
    this.paginator = new Paginator(5, false)
    this.inspections = []
    this.contract = contract
    makeAutoObservable(this)
  }

  fetchContractInspections = async (contractId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<GetApartmentInspectionsResponse>(
      ApartmentAgent.getAllInspections(
        contractId,
        this.paginator.limit,
        this.paginator.offset,
        this.contract.id,
      ),
    )

    if (!err && res) {
      this.updateInspectionsByContract(res)
    } else {
      this.loader.setError("Ошибка загрузки инспекций", err)
    }

    this.loader.endLoading()
  }

  updateInspectionsByContract = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionListModel) =>
      InspectionsStore.getInspectionParams(inspection)

    const result = this.paginator.getPageResponse<
      ApartmentInspectionListModel,
      InspectionRowParams
    >(res, this.inspections, mapper)

    this.inspections = result
  }
}

export default ContractInspectionsStore
