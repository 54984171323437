import { makeAutoObservable } from "mobx"
import { Loader, resHandler, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { ObjectNeedInitialParams } from "../types/store/apartments"
import {
  ObjectsWithoutInitialResponse,
  ObjectWithoutInitialModel,
} from "../types/api/apartments"
import ApartmentAgent from "../agent/ApartmentAgent"
import ApartmentsPageStore from "./ApartmentsPageStore"

class ObjectsNeedInitialStore {
  value: number | null

  done: number | null

  objects: ObjectNeedInitialParams[]

  loader: Loader

  filter: SortingFilter

  actionLoader: Loader

  constructor() {
    this.value = null
    this.done = null
    this.objects = []
    this.loader = new Loader()
    this.filter = new SortingFilter()
    this.actionLoader = new Loader()
    makeAutoObservable(this)
  }

  initApartmentsPage = async () => {
    this.loader.startLoading()

    await this.fetchAll(true)

    this.loader.endLoading()
  }

  fetchAll = async (initial?: boolean) => {
    this.actionLoader.startLoading()

    const response = await to<ObjectsWithoutInitialResponse>(
      ApartmentAgent.getObjectsNeedInitial(this.filter.filterParams),
    )

    resHandler(response, this.actionLoader, (res) =>
      this.updateApartments(res, initial),
    )
  }

  fetchAllForMap = async () => {
    await this.fetchAll()
  }

  updateApartments = (
    res: ObjectsWithoutInitialResponse,
    isInitial?: boolean,
  ) => {
    if (isInitial) {
      this.value = res.count
      this.done = res.completed_count
    }

    this.objects = res.results.map((object) =>
      ObjectsNeedInitialStore.getApartmentParams(object),
    )
  }

  static getApartmentParams = (
    object: ObjectWithoutInitialModel,
  ): ObjectNeedInitialParams => ({
    ...ApartmentsPageStore.getApartmentParams(object.apartment),
    daysWithoutInitial: object.since_object_creation,
    isOverdue: object.since_object_creation > 14,
  })
}

export default ObjectsNeedInitialStore
