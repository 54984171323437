import React from "react"
import { Box } from "kui-basic"
import { useFieldArray } from "react-hook-form"
import FieldsHeading from "../../../../components/common/FieldsHeading"
import ImagesBlock from "../../../../components/common/ImagesBlock"
import { RoomImagesBlockProps } from "./types"
import { roomLabelsByType } from "./content"

const RoomImagesBlock = (props: RoomImagesBlockProps) => {
  const { form } = props
  const { fields } = useFieldArray({
    control: form.control,
    name: "roomsImages",
  })

  return (
    <>
      <FieldsHeading title="Фотографии" />
      {fields.map((field, index) => (
        <Box mt={1}>
          <ImagesBlock
            label={roomLabelsByType[field.type]}
            form={form}
            name={`roomsImages.${index}`}
            subName="images"
            disabled
          />
        </Box>
      ))}
    </>
  )
}

export default RoomImagesBlock
