import React, { useEffect, useState } from "react"
import { Container } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import { LoaderState } from "kui-crm"
import { ObjectForMapParams } from "kui-crm_actions"
import Page from "../../../components/common/Page"
import ApartmentsPageTitle from "./ApartmentsPageTitle"
import ApartmentsListFilter from "./ApartmentsListFilter"
import { ApartmentsListWithMapProps, ApartmentsView } from "./types"
import ApartmentsMap from "./ApartmentsMap"

const ApartmentsListWithMap = <T extends ObjectForMapParams>(
  props: ApartmentsListWithMapProps<T>,
) => {
  const { apartmentsStore, apartmentsForMap, listComponent } = props
  const { paginator, getLabel } = props
  const { filter, initApartmentsPage, loader, actionLoader } = apartmentsStore
  const [view, setView] = useState<ApartmentsView>("list")

  useEffect(() => {
    initApartmentsPage()

    return () => {
      filter.clearFilter()
    }
  }, [])

  if (loader.isLoading) {
    return <Loading height="100vh" />
  }

  return (
    <Page>
      <Container>
        <ApartmentsPageTitle
          apartmentsStore={apartmentsStore}
          view={view}
          setView={setView}
        />
        <ApartmentsListFilter
          apartmentsStore={apartmentsStore}
          paginator={paginator}
        />
        {view === "list" ? (
          listComponent
        ) : (
          <ApartmentsMap
            getLabel={getLabel}
            apartmentsStore={apartmentsStore}
            apartmentsForMap={apartmentsForMap}
          />
        )}

        <LoaderState loader={actionLoader} onlyError />
      </Container>
    </Page>
  )
}

export default observer(ApartmentsListWithMap)
