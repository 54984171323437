import React, { ReactElement } from "react"
import { PartialMeterInfoFields } from "kui-crm_actions"
import MeterImagesStep from "../MeterImagesStep"
import WaterMeterInfoStep from "./index"

const renderWaterMeterSteps = (
  meters: PartialMeterInfoFields[],
  withCheckValue?: boolean,
): ReactElement[][] =>
  meters.map((meter, index) => [
    <WaterMeterInfoStep
      withCheckValue={withCheckValue}
      index={index}
      key={`meter_info_${index}`}
    />,
    <MeterImagesStep
      key={`meter_photo_${index}`}
      index={index}
      resource={meter.resource}
    />,
  ])

export default renderWaterMeterSteps
