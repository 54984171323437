const inspectionTypeLabels = {
  initial: "Первичный осмотр",
  inventory: "Опись",
  transfer: "Акт приема-передачи",
  regular: "Регулярный осмотр",
  appraisal: "",
  impairments: "",
}

export default inspectionTypeLabels
