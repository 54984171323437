import React from "react"
import { observer } from "mobx-react"
import { declineByNumber } from "kui-utils"
import useStore from "../../../hooks/useStore"
import ApartmentsListWithMap from "../ApartmentsListWithMap"
import ObjectsNeedInitialList from "./ObjectsNeedInitialList"
import { ObjectNeedInitialParams } from "../../../types/store/apartments"

const ObjectsNeedInitialPage = () => {
  const { apartmentsPageStore } = useStore()
  const { objects } = apartmentsPageStore.objectsNeedInitialStore

  const getLabel = (inspection: ObjectNeedInitialParams) =>
    declineByNumber(inspection.daysWithoutInitial, ["день", "дня", "дней"])

  return (
    <ApartmentsListWithMap
      apartmentsStore={apartmentsPageStore.objectsNeedInitialStore}
      apartmentsForMap={objects}
      listComponent={<ObjectsNeedInitialList />}
      getLabel={getLabel}
    />
  )
}

export default observer(ObjectsNeedInitialPage)
