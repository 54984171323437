import * as yup from "yup"
import {
  HouseServicesInfoFieldsSchema,
  TelecommunicationsInfoFieldsSchema,
} from "kui-crm_actions"

const InventoryMainInfoStepSchema = yup.object().shape(
  {
    ...HouseServicesInfoFieldsSchema,
    ...TelecommunicationsInfoFieldsSchema,
  },
  [["homePhone", "homePhone"]],
)

export default InventoryMainInfoStepSchema
