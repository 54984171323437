/** @jsxImportSource @emotion/react */
import * as React from "react"
import { SyntheticEvent } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom"
import { Caption, theme } from "kui-basic"
import { MenuLinkProps } from "./types"

const MenuLink = ({ link, title, isActive }: MenuLinkProps) => {
  const navigate = useNavigate()

  const handleClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (link) navigate(link)
  }

  return (
    <StyledLink
      href={link}
      onClick={handleClick}
      css={isActive && activeStyles}
    >
      <Caption size="s" disableUserSelect>
        {title}
      </Caption>
    </StyledLink>
  )
}

export default MenuLink

const StyledLink = styled.a`
  padding: 16px 16px 16px;
  width: 100%;
  text-decoration: none;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    p {
      color: ${theme.palette.grey.fiftyP};
    }
  }
`

const activeStyles = css`
  p {
    color: ${theme.palette.grey.fiftyP};
  }
`
