import React from "react"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InitialInspectionFormFields } from "kui-crm_actions"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import FullInitialInfoStepSchema from "./schema"
import { InspectionFormProps } from "../../../types"
import InitialInspectionFields from "../../../../../components/common/InspectionFields/InitialInspectionFields"
import RoomImagesBlock from "../../../common/RoomImagesBlock"

const FullInitialInfoStep = ({ handleSubmit }: InspectionFormProps) => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<InitialInspectionFormFields>({
    defaultValues: { ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(FullInitialInfoStepSchema),
  })
  const roomsNumber = useWatch({
    control: form.control,
    name: [
      "roomsNumber",
      "bedroomsNumber",
      "bathroomsNumber",
      "restroomsNumber",
      "combinedBathroomsNumber",
    ],
  })
  const roomsParams = {
    roomsNumber: roomsNumber[0],
    bedroomsNumber: roomsNumber[1],
    bathroomsNumber: roomsNumber[2],
    restroomsNumber: roomsNumber[3],
    combinedBathroomsNumber: roomsNumber[4],
  }

  const handleFormSubmit = async (data: InitialInspectionFormFields) => {
    await handleSubmit("initial", data)
  }

  return (
    <FormWrapper
      title="Проверьте и подтвердите информацию"
      form={form}
      onSubmit={handleFormSubmit}
      onPrev={formStore.prevStep}
      label="Подтвердить"
    >
      <InitialInspectionFields form={form as any} />
      <RoomImagesBlock type="initial" form={form} roomsParams={roomsParams} />
    </FormWrapper>
  )
}

export default observer(FullInitialInfoStep)
