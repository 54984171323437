import {
  getAdditionalInfoStepFields,
  getHouseServicesInfoStep,
  getMetersInfoStepFields,
  getTelecommunicationsInfoStepFields,
} from "kui-crm_actions"
import { MetersCountParams, ResourceTypes } from "kui-crm"
import { UseFormReturn } from "react-hook-form"
import {
  houseServicesFieldsSettings,
  telecommunicationFieldsSettings,
} from "../InventoryMainInfoStep/content"
import { additionalInfoFieldsSettings } from "../InventoryAdditionalInfoStep/content"
import { metersInfoStepFieldsSettings } from "../InventoryMetersStep/content"
import {
  MeterInfoFields,
  MeterInfoFieldsProps,
} from "../FullMeterInfoStep/useMeterInfoFields"

const houseServicesFields = getHouseServicesInfoStep(
  houseServicesFieldsSettings,
)
const telecommunicationFields = getTelecommunicationsInfoStepFields(
  telecommunicationFieldsSettings,
)
const additionalFields = getAdditionalInfoStepFields(
  additionalInfoFieldsSettings,
)
const metersInfoFields = getMetersInfoStepFields(metersInfoStepFieldsSettings)

const renderMetersSteps = (
  getMetersFields: (props: MeterInfoFieldsProps) => MeterInfoFields,
  form: UseFormReturn<any>,
  maxMeters?: MetersCountParams | null,
) => {
  const meters = [
    ...new Array(Number(maxMeters?.water || 0)).fill("water"),
    ...new Array(Number(maxMeters?.electricity || 0)).fill("electricity"),
    ...new Array(Number(maxMeters?.gas || 0)).fill("gas"),
    ...new Array(Number(maxMeters?.heating || 0)).fill("heating"),
  ]
  return meters
    .map((resource, index) => getMetersFields({ resource, index, form }))
    .flat()
}

const getMeters = (maxMeters?: MetersCountParams | null): ResourceTypes[] => [
  ...new Array(Number(maxMeters?.water || 0)).fill("water"),
  ...new Array(Number(maxMeters?.electricity || 0)).fill("electricity"),
  ...new Array(Number(maxMeters?.gas || 0)).fill("gas"),
  ...new Array(Number(maxMeters?.heating || 0)).fill("heating"),
]

const inventoryFields = [
  ...houseServicesFields,
  ...telecommunicationFields,
  ...additionalFields,
  ...metersInfoFields,
]

const defaultFillingFields = {
  tag: null,
  serialNumber: "",
  numberOfSubjects: null,
  name: "",
  type: "",
  instruction: null,
  tempImages: [],
  images: [],
}

export default inventoryFields
export { renderMetersSteps, defaultFillingFields, getMeters }
