import { requests } from "kui-crm"
import { ObjectsWithoutInitialResponse } from "../types/api/apartments"

const ApartmentAgent = {
  getById: (id: number) => requests.get(`/apartments/${id}`),
  getInspectionsOnMap: (searchQuery: string) =>
    requests.get(`/dashboard/reminders/upcoming-inspections?${searchQuery}`),
  getUpcomingInspections: (
    offset: number,
    limit: number,
    searchQuery: string,
  ) =>
    requests.get(
      `/actions/upcoming_inspections?offset=${offset}&limit=${limit}${searchQuery}`,
    ),
  getObjectsNeedInitial: (filter: string) =>
    requests.get<ObjectsWithoutInitialResponse>(
      `/dashboard/reminders/objects-need-initial-inspection?${filter}`,
    ),
  getAllInspections: (
    apartmentId: number,
    limit: number,
    offset: number,
    rentalContractId?: number,
  ) =>
    requests.get(
      `/apartments/${apartmentId}/inspections?limit=${limit}&offset=${offset}${
        rentalContractId ? `&rental_contract=${rentalContractId}` : ""
      }`,
    ),
}

export default ApartmentAgent
