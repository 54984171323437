import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { deleteCookie, Loader } from "kui-utils"
import {
  authFlag,
  csrfTokenKey,
  sessionIdKey,
  SignInFormFields,
  AuthAgent,
} from "kui-crm"

class AuthStore {
  isAuthed: boolean

  loader: Loader

  constructor() {
    this.isAuthed = !!localStorage.getItem(authFlag)
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  signIn = async (data: SignInFormFields) => {
    this.loader.startLoading()
    const [err] = await to(AuthAgent.signIn(data))

    runInAction(() => {
      if (!err) {
        this.setAuthData()
      } else {
        this.loader.setError("signin", err)
      }
    })
    this.loader.endLoading()
  }

  cleanAuthData = () => {
    AuthStore.removeCookies()
    localStorage.removeItem(authFlag)

    this.isAuthed = false
  }

  setAuthData = () => {
    this.isAuthed = true
    localStorage.setItem(authFlag, "true")
  }

  logout = async () => {
    this.loader.startLoading()
    const [err] = await to(AuthAgent.logout())
    runInAction(() => {
      if (!err) {
        this.cleanAuthData()
      } else {
        this.loader.setError("logout", err)
      }
      this.loader.endLoading()
    })
  }

  static removeCookies = () => {
    deleteCookie(csrfTokenKey)
    deleteCookie(sessionIdKey)
  }
}

export default AuthStore
