import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { HalfArrowNextIcon } from "kui-icon"
import { LabelStylesProps, OptionButtonProps } from "./types"

const OptionButton = (props: OptionButtonProps) => {
  const { label, value, onClick, disabled } = props

  const handleClick = () => {
    if (!disabled) onClick(value)
  }

  return (
    <StyledWrapper
      container
      alignItems="center"
      wrap="nowrap"
      justify="space-between"
      onClick={handleClick}
    >
      <StyledLabel disabled={disabled} size="xs" color="fiftyP">
        {label}
      </StyledLabel>
      {!disabled && <StyledIcon />}
    </StyledWrapper>
  )
}

export default OptionButton

const StyledWrapper = styled(Grid)`
  &:not(:first-of-type) {
    padding-top: 12px;
  }
  &:not(:last-of-type) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledIcon = styled(HalfArrowNextIcon)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledLabel = styled(Caption)<LabelStylesProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
