import React, { useEffect } from "react"
import * as Sentry from "@sentry/react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { observer } from "mobx-react"
import { ThemeProvider } from "@emotion/react"
import { theme } from "kui-basic"
import { KUISettings } from "kui-crm"
import { Loading } from "kui-complex"
import "kui-swiper/index.css"
import routes from "./routes"
import useStore from "./hooks/useStore"
import { RouteInterface } from "./routes/types"
import store from "./store"

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://2731a4f79a1a409380865d3705440ff4@sentry.maroom.ru/14",
    tracesSampleRate: 1.0,
  })
}

KUISettings.setSettings({
  mapKey: process.env.REACT_APP_MAP_KEY || "",
  apiUrl: process.env.REACT_APP_API_URL,
  appUrl: process.env.REACT_APP_URL,
  errorHandler: store.authStore.cleanAuthData,
})

const renderRoute = (route: RouteInterface, isAuthed: boolean) => {
  let content
  if (route.auth) {
    content = isAuthed ? route.content : <Navigate to="/signin" replace />
  } else {
    content = !isAuthed ? route.content : <Navigate to="/" replace />
  }
  return <Route key={route.path} path={route.path} element={content} />
}

function App() {
  const { authStore, cabinetStore } = useStore()
  const { isAuthed } = authStore
  const { initUserCabinet, fullName, loader } = cabinetStore

  useEffect(() => {
    if (isAuthed && !fullName) {
      initUserCabinet()
    }
  }, [isAuthed, fullName])

  if (loader.isLoading && isAuthed) {
    return <Loading height="100vh" />
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>{routes.map((route) => renderRoute(route, isAuthed))}</Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default observer(App)
