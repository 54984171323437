import React from "react"
import { useForm } from "react-hook-form"
import { TransferInspectionFormFields } from "kui-crm_actions"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import FullTransferInfoStepSchema from "./schema"
import { InspectionFormProps } from "../../../types"
import TransferInspectionFields from "../../../../../components/common/InspectionFields/TransferInspectionFields"
import RoomImagesBlock from "../../../common/RoomImagesBlock"

const FullTransferInfoStep = ({ handleSubmit }: InspectionFormProps) => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<TransferInspectionFormFields>({
    defaultValues: { ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(FullTransferInfoStepSchema),
  })

  const handleFormSubmit = async (data: TransferInspectionFormFields) => {
    await handleSubmit("transfer", data)
  }

  return (
    <FormWrapper
      title="Проверьте и подтвердите информацию"
      form={form}
      onSubmit={handleFormSubmit}
      onPrev={formStore.prevStep}
      label="Подтвердить"
    >
      <TransferInspectionFields form={form as any} />
      <RoomImagesBlock
        type="transfer"
        form={form}
        roomsParams={apartmentInfo}
      />
    </FormWrapper>
  )
}

export default observer(FullTransferInfoStep)
