import React from "react"
import { Box, Grid, Heading } from "kui-basic"
import { FieldsHeadingProps } from "./types"

const FieldsHeading = ({ title }: FieldsHeadingProps) => (
  <Grid item xs={12}>
    <Box mt={2} mb={1}>
      <Heading size="h3">{title}</Heading>
    </Box>
  </Grid>
)

export default FieldsHeading
