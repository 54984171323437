import { InputParams } from "kui-crm"
import ImagesBlock from "../../../../components/common/ImagesBlock"

const getMeterImagesStep = (index: number): InputParams<any> => ({
  name: `meters.${index}.images`,
  variant: "custom",
  CustomInput: ImagesBlock,
  withComment: false,
})

export default getMeterImagesStep
