import * as yup from "yup"
import {
  BasicApartmentInfoFieldsSchema,
  DetailApartmentInfoFieldsSchema,
  HouseInfoFieldsSchema,
  RoomsApartmentInfoFieldsSchema,
  SecurityApartmentInfoFieldsSchema,
} from "kui-crm_actions"

const FullInitialInfoStepSchema = yup.object().shape({
  ...BasicApartmentInfoFieldsSchema,
  ...HouseInfoFieldsSchema,
  ...SecurityApartmentInfoFieldsSchema,
  ...RoomsApartmentInfoFieldsSchema,
  ...DetailApartmentInfoFieldsSchema,
})

export default FullInitialInfoStepSchema
