import React from "react"
import styled from "@emotion/styled"
import { Box, InputWithAdornments } from "kui-basic"
import { SearchIcon } from "kui-icon"
import { css, Theme } from "@emotion/react"
import { observer } from "mobx-react"
import { ApartmentsListFilterProps } from "./types"

const ApartmentsListFilter = (props: ApartmentsListFilterProps) => {
  const { apartmentsStore, paginator } = props
  const { filter } = apartmentsStore

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    filter.updateSearchQuery(value)
    if (!value && paginator) {
      paginator.clearOffset()
      paginator.startFetching()
    }
  }

  return (
    <Box mb={3}>
      <StyledInput
        startIcon={<SearchIcon />}
        placeholder="Начните вводить адрес для поиска.."
        onChange={handleChange}
      />
    </Box>
  )
}

export default observer(ApartmentsListFilter)

const inputCSS = ({ theme }: { theme: Theme }) => css`
  padding-top: 13px;
  border-radius: 66px;
  height: calc(40px * 16 / 14);
  border: 1.5px solid transparent;
  ::placeholder {
    color: ${theme.palette.grey.thirty};
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: ${theme.palette.grey.thirty};
  }
`

const StyledInput = styled(InputWithAdornments)`
  height: 40px !important;
  & .KUI-Input_label {
    top: -9px;
  }
  & .KUI-Input {
    ${inputCSS};
  }
  & .KUI-InputAdornment_start-icon {
    padding-left: 8px;
  }
`
