import { TransferInspectionTypeValues } from "../../../../../utils/listOptions"

const transferTypeFields = [
  {
    name: "transferType",
    label: "Тип акта приема-передачи",
    variant: "select",
    options: TransferInspectionTypeValues,
  },
]

export default transferTypeFields
