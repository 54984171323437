import React from "react"
import { InspectionTypes } from "kui-crm"
import getRoomsImagesSteps from "./steps"
import InspectionImagesStep from "../index"
import { InspectionRoomsFields } from "../types"

const renderRoomsImagesSteps = (
  type: InspectionTypes,
  roomsParams?: InspectionRoomsFields | null,
) => {
  const fields = getRoomsImagesSteps(type, roomsParams)

  return fields.map((field, index) => (
    <InspectionImagesStep
      {...field}
      entityName="roomsImages"
      name={`${index}`}
    />
  ))
}

export default renderRoomsImagesSteps
