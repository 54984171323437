import React from "react"
import { useFieldArray } from "react-hook-form"
import { FillingInfoStepFields } from "kui-crm_actions"
import { defaultFillingFields } from "../content"
import HeadingWithButton from "../../../../../../components/common/HeadingWithButton"
import FillingGroupFields from "../FillingGroupFields"
import { FillingGroupsFieldsProps } from "./types"

const FillingGroupsFields = (props: FillingGroupsFieldsProps) => {
  const { form, disabled } = props
  const {
    fields: fillingFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "fillingList",
  })

  const addFillingFields = () => {
    append(defaultFillingFields as FillingInfoStepFields)
  }

  return (
    <>
      <HeadingWithButton
        title="Наполнение"
        disabled={disabled}
        onClick={addFillingFields}
      />
      {fillingFields.map((filling, index) => (
        <FillingGroupFields
          key={filling.id}
          index={index}
          form={form}
          remove={remove}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default FillingGroupsFields
