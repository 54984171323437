import { makeAutoObservable } from "mobx"
import { ObjectForMapParams } from "kui-crm_actions"
import { ObjectForMapModel } from "kui-crm"
import { getPhoneParamsFromString } from "kui-utils"
import ObjectsNeedInitialStore from "./ObjectsNeedInitialStore"
import UpcomingInspectionsStore from "./UpcomingInspectionsStore"

class ApartmentsPageStore {
  upcomingInspectionsStore: UpcomingInspectionsStore

  objectsNeedInitialStore: ObjectsNeedInitialStore

  constructor() {
    this.upcomingInspectionsStore = new UpcomingInspectionsStore()
    this.objectsNeedInitialStore = new ObjectsNeedInitialStore()
    makeAutoObservable(this)
  }

  static getApartmentParams = (
    apartment: ObjectForMapModel,
  ): ObjectForMapParams => ({
    id: apartment.id,
    apartment: {
      id: apartment?.id,
      address: apartment?.object_name || apartment?.address,
      previewImage: apartment?.main_image?.image_small_url || "",
      roomsCount: Number(apartment.rooms_number) || 0,
    },
    tenant: ApartmentsPageStore.getClientParams(apartment?.renter),
    landlord: ApartmentsPageStore.getClientParams(apartment?.owner),
    metroStations: apartment?.metro_stations.map((station) => ({
      name: station.name,
      color: `#${station.line.hex_color}`,
    })),
    location: {
      lat: apartment?.geolocation.lat,
      lng: apartment?.geolocation.lon,
    },
  })

  static getClientParams = (client: ObjectForMapModel["renter"]) =>
    client
      ? {
          id: client.id,
          fullName: client.full_name,
          phoneNumber: client.phone
            ? getPhoneParamsFromString(client.phone)
            : null,
        }
      : null
}

export default ApartmentsPageStore
