export type HeaderRouteParams = {
  link: string
  title: string
}

const headerRoutes: HeaderRouteParams[] = [
  { title: "Список квартир", link: "/" },
]

export { headerRoutes }
