import React from "react"
import { Modal } from "kui-basic"
import { MapWrapper } from "kui-complex"
import { MapComponent } from "kui-crm"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import { ApartmentMapProps } from "./types"

const ApartmentMap = (props: ApartmentMapProps) => {
  const { apartmentInfo } = useApartmentStore()

  return (
    <StyledModal {...props}>
      <MapWrapper>
        <MapComponent center={apartmentInfo?.geolocation} withMarker />
      </MapWrapper>
    </StyledModal>
  )
}

export default observer(ApartmentMap)

const StyledModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  max-height: unset !important;
  .KUI-Modal_close-button {
    background: white;
    border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
    width: 40px;
    height: 40px;
  }
`
