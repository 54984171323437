import * as yup from "yup"
import {
  DetailApartmentInfoFieldsSchema,
  HouseInfoFieldsSchema,
  RoomsApartmentInfoFieldsSchema,
  SecurityApartmentInfoFieldsSchema,
} from "kui-crm_actions"

const ApartmentMainInfoStepSchema = yup.object({
  ...HouseInfoFieldsSchema,
  ...SecurityApartmentInfoFieldsSchema,
  ...RoomsApartmentInfoFieldsSchema,
  ...DetailApartmentInfoFieldsSchema,
})

export default ApartmentMainInfoStepSchema
