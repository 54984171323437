import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { declineByNumber } from "kui-utils"
import { DaysWithOverdueProps, DotStylesProps } from "./types"

const DaysWithOverdue = (props: DaysWithOverdueProps) => {
  const { days, isOverdue } = props
  const daysLabel = declineByNumber(days, ["день", "дня", "дней"])

  return (
    <StyledWrapper container alignItems="center" wrap="nowrap">
      <Caption size="xs" color="fiftyP">
        {daysLabel} с момента создания объекта
      </Caption>
      <StyledDot isOverdue={isOverdue} />
    </StyledWrapper>
  )
}

export default DaysWithOverdue

const StyledWrapper = styled(Grid)`
  width: fit-content;
`

const StyledDot = styled.div<DotStylesProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
  opacity: ${({ isOverdue }) => (isOverdue ? 1 : 0)};
  background: ${({ theme }) => theme.palette.red.fiftyP};
`
