import React, { useState } from "react"
import { MultistepForm } from "kui-crm"
import { observer } from "mobx-react"
import { renderMetersSteps } from "kui-crm_actions"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import InventoryMainInfoStep from "./InventoryMainInfoStep"
import InventoryAdditionalInfoStep from "./InventoryAdditionalInfoStep"
import InventoryMetersStep from "./InventoryMetersStep"
import renderRoomsImagesSteps from "../../common/InspectionImagesStep/imagesSteps/renderSteps"
import FullMeterInfoStep from "./FullMeterInfoStep"
import FillingImagesStep from "./FillingImagesStep"
import FillingInfoStep from "./FillingInfoStep"
import FullInventoryInfoStep from "./FullInventoryInfoStep"
import { InspectionFormProps } from "../../types"
import MeterImagesStep from "../../common/MeterImagesStep"

const InventoryInspectionForm = (props: InspectionFormProps) => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const [fillingList, setFillingList] = useState([0])
  const formStore = inspectionsStore.creationForm
  const maxMeters = formStore.fields?.maxMeters

  const addFilling = () => {
    setFillingList((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const removeFilling = () => {
    setFillingList((prev) => prev.slice(0, prev.length - 1))
  }

  return (
    <MultistepForm stepShift={1} form={formStore}>
      <InventoryMainInfoStep />
      <InventoryAdditionalInfoStep />
      <InventoryMetersStep />
      {renderMetersSteps(FullMeterInfoStep, MeterImagesStep, maxMeters)}
      {fillingList.map((filling) => [
        <FillingInfoStep
          key={`info_${filling}`}
          index={filling}
          onPrev={removeFilling}
        />,
        <FillingImagesStep
          key={`images_${filling}`}
          index={filling}
          addStep={addFilling}
        />,
      ])}
      {renderRoomsImagesSteps("inventory", apartmentInfo)}
      <FullInventoryInfoStep {...props} />
    </MultistepForm>
  )
}

export default observer(InventoryInspectionForm)
