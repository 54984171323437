import React from "react"
import { InventoryInspectionFormFields } from "kui-crm_actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import FullInventoryInfoStepSchema from "./schema"
import { InspectionFormProps } from "../../../types"
import InventoryInspectionFields from "../../../../../components/common/InspectionFields/InventoryInspectionFields"
import RoomImagesBlock from "../../../common/RoomImagesBlock"

const FullInventoryInfoStep = ({ handleSubmit }: InspectionFormProps) => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const form = useForm<InventoryInspectionFormFields>({
    defaultValues: { ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(FullInventoryInfoStepSchema),
  })

  const handleFormSubmit = async (data: InventoryInspectionFormFields) => {
    await handleSubmit("inventory", data)
  }

  return (
    <FormWrapper
      title="Проверьте и подтвердите информацию"
      form={form}
      onSubmit={handleFormSubmit}
      onPrev={formStore.prevStep}
      label="Подтвердить"
    >
      <InventoryInspectionFields form={form as any} />
      <RoomImagesBlock
        type="inventory"
        form={form}
        roomsParams={apartmentInfo}
      />
    </FormWrapper>
  )
}

export default observer(FullInventoryInfoStep)
