import { requests } from "kui-crm"

const ApartmentInspectionAgent = {
  getById: (apartmentId: number, inspectionId: number) =>
    requests.get(`/apartments/${apartmentId}/inspections/${inspectionId}`),
  resign: (apartmentId: number, id: number) =>
    requests.post(
      `/apartments/${apartmentId}/inspections/${id}/resign`,
      undefined,
    ),
}

export default ApartmentInspectionAgent
