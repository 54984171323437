import React from "react"
import {
  getPartialMeterInfoSchema,
  PartialMeterInfoFields,
  PartialMeterInfoStepProps,
} from "kui-crm_actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { InputByType, getPartialMeterInfoFields } from "kui-crm"
import { observer } from "mobx-react"
import meterLabels, { meterTypes, partialMeterInfoSettings } from "./content"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../components/common/FormWrapper"

const PartialMeterInfoStep = (props: PartialMeterInfoStepProps) => {
  const { resource, index, type, withCheckValue } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const label = `Проверьте и введите данные счетчика ${
    type ? `${meterTypes[type]} ` : ""
  }${meterLabels[resource]}`
  const meter = formStore.fields?.meters?.[index]
  const fields = getPartialMeterInfoFields(
    resource,
    type,
    meter,
    withCheckValue,
    "",
    partialMeterInfoSettings,
  )
  const schema = getPartialMeterInfoSchema(fields)

  const form = useForm({
    values: meter,
    resolver: yupResolver(schema),
  })

  const handleSubmit = (data: PartialMeterInfoFields) => {
    formStore.replaceFieldInArray("meters", { ...data, resource }, index)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title={label}
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item key={field.name} xs={12}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(PartialMeterInfoStep)
