import React from "react"
import InspectionCommonInfoForm from "../InspectionFirstScreen/InspectionCommonInfoForm"
import ApartmentInfoFooter from "../InspectionFirstScreen/ApartmentInfoFooter"

const InspectionFirstStep = () => (
  <>
    <InspectionCommonInfoForm />
    <ApartmentInfoFooter />
  </>
)

export default InspectionFirstStep
