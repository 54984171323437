import React, { useState } from "react"
import { getFillingInfoFields, FillingInfoFieldsSchema } from "kui-crm_actions"
import { observer } from "mobx-react"
import {
  ApartmentFillingVariants,
  FillingInfoFormFields,
  InputByType,
} from "kui-crm"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { FillingInfoStepProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import fillingInfoStepSettings from "./content"

const FillingInfoStep = ({ index, onPrev }: FillingInfoStepProps) => {
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const [type, setType] = useState<ApartmentFillingVariants | null>(null)
  const defaultValues = formStore.fields?.fillingList?.[index] || {}
  const form = useForm<FillingInfoFormFields>({
    defaultValues: {
      type,
      ...defaultValues,
    },
    // @ts-ignore
    resolver: yupResolver(FillingInfoFieldsSchema),
  })

  const fields = getFillingInfoFields(
    true,
    type!,
    setType,
    fillingInfoStepSettings,
  )

  const handleSubmit = (data: FillingInfoFormFields) => {
    formStore.replaceFieldInArray("fillingList", data, index)
    formStore.nextStep()
  }

  const handlePrev = () => {
    formStore.prevStep()
    if (!Object.keys(defaultValues).length) onPrev()
  }

  return (
    <FormWrapper
      title="Опишите по очереди наполнение квартиры"
      form={form}
      onSubmit={handleSubmit}
      onPrev={handlePrev}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(FillingInfoStep)
