import React from "react"
import { Container } from "kui-basic"
import styled from "@emotion/styled"
import ApartmentInfoBlock from "./ApartmentInfoBlock"
import InspectionTabsPanel from "../InspectionTabsPanel"

const InspectionFirstScreen = () => (
  <StyledWrapper>
    <ApartmentInfoBlock />
    <StyledContainer>
      <InspectionTabsPanel />
    </StyledContainer>
  </StyledWrapper>
)

export default InspectionFirstScreen

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 49px);
`

const StyledContainer = styled(Container)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
