import { Loader, MultistepForm, Paginator, resHandler } from "kui-utils"
import { makeAutoObservable } from "mobx"
import {
  ApartmentInspectionListModel,
  getInspectionFeedbackStatus,
  InspectionFeedbackInterface,
  InspectionTypes,
} from "kui-crm"
import to from "await-to-js"
import { DateTime } from "luxon"
import { inspectionsRequests } from "kui-crm_actions"
import _ from "lodash"
import ApartmentStore from "./ApartmentStore"
import ContractInspectionsStore from "./ContractInspectionsStore"
import { InspectionRowParams } from "../../types/store/inspections"
import { GetApartmentInspectionsResponse } from "../../types/api/inspections"
import ApartmentAgent from "../../agent/ApartmentAgent"

class InspectionsStore {
  inspectionsByContract: ContractInspectionsStore[]

  apartmentInspections: InspectionRowParams[]

  loader: Loader

  actionLoader: Loader

  apartmentStore: ApartmentStore

  paginator: Paginator

  creationForm: MultistepForm<any>

  indexDB: any

  canUpdateDB: boolean

  canLoadDB: boolean

  constructor(apartmentStore: ApartmentStore) {
    this.inspectionsByContract = []
    this.apartmentInspections = []
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.apartmentStore = apartmentStore
    this.paginator = new Paginator()
    this.creationForm = new MultistepForm<any>()
    this.indexDB = null
    this.canUpdateDB = false
    this.canLoadDB = false
    makeAutoObservable(this)
  }

  createInspection = async (type: InspectionTypes, data: any) => {
    if (this.apartmentId && type) {
      return inspectionsRequests[
        `create${_.capitalize(
          type,
        )}Inspection` as keyof typeof inspectionsRequests
      ](data, this.apartmentId, this.actionLoader)
    }
    return null
  }

  fetchApartmentInspections = async () => {
    const apartmentId = this.apartmentStore.apartmentInfo?.id
    if (apartmentId) {
      this.loader.startLoading()

      const response = await to<GetApartmentInspectionsResponse>(
        ApartmentAgent.getAllInspections(
          apartmentId,
          this.paginator.limit,
          this.paginator.offset,
        ),
      )

      resHandler(
        response,
        this.loader,
        this.updateInspections,
        "Ошибка загрузки инспекций",
      )
    }
  }

  updateInspections = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionListModel) =>
      InspectionsStore.getInspectionParams(inspection)

    const result = this.paginator.getPageResponse<
      ApartmentInspectionListModel,
      InspectionRowParams
    >(res, this.apartmentInspections, mapper)

    this.apartmentInspections = result

    this.inspectionsByContract =
      this.apartmentStore.apartmentInfo?.contracts.map(
        (contract) => new ContractInspectionsStore(contract),
      ) || []
  }

  setIndexDB = (indexDB: any) => {
    this.indexDB = indexDB
  }

  setCanUpdateDB = (value: boolean) => {
    this.canUpdateDB = value
  }

  setCanLoadDB = (value: boolean) => {
    this.canLoadDB = value
  }

  get apartmentId() {
    return this.apartmentStore.apartmentInfo?.id
  }

  reset = () => {
    this.canUpdateDB = false
    this.creationForm.resetForm()
    this.inspectionsByContract = []
    this.apartmentInspections = []
  }

  static getInspectionParams = (
    inspection: ApartmentInspectionListModel,
  ): InspectionRowParams => ({
    id: inspection.id,
    type: inspection.inspection_type,
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
    landlordFeedback: InspectionsStore.getInspectionFeedbackParams(
      inspection.owner_feedback,
    ),
    tenantFeedback: InspectionsStore.getInspectionFeedbackParams(
      inspection.renter_feedback,
    ),
  })

  static getInspectionFeedbackParams = (
    feedback?: InspectionFeedbackInterface | null,
  ) => {
    if (!feedback) return null

    return {
      status: getInspectionFeedbackStatus(feedback),
      comment: feedback.comment,
    }
  }
}

export default InspectionsStore
