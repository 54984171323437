import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { Link } from "kui-crm"
import { InspectionRowProps } from "./types"
import InspectionFeedbackStatus from "../InspectionFeedbackStatus"
import inspectionTypeLabels from "../../InspectionFirstScreen/InspectionTypeButton/content"
import { DynamicRoutes } from "../../../../../routes"
import useApartmentStore from "../../../../../hooks/useApartmentStore"

const InspectionRow = (props: InspectionRowProps) => {
  const { inspection } = props
  const { apartmentInfo } = useApartmentStore()

  if (!apartmentInfo?.id) return null

  const inspectionLink = DynamicRoutes.inspection(
    apartmentInfo.id,
    inspection.id,
  )

  return (
    <StyledLink href={inspectionLink}>
      <StyledWrapper container alignItems="center">
        <InspectionFeedbackStatus inspection={inspection} />
        <div>
          <Caption weight={500}>
            {inspectionTypeLabels[inspection.type]}
          </Caption>
          <Caption size="s" weight={500} color="fiftyP">
            {inspection.date?.toFormat("dd.MM.yyyy")}
          </Caption>
        </div>
      </StyledWrapper>
    </StyledLink>
  )
}

export default InspectionRow

const StyledLink = styled(Link)`
  display: block;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const StyledWrapper = styled(Grid)`
  padding: 8px 16px;
  border-radius: 8px;
  background: white;
`
