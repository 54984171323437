import React from "react"
import {
  getDetailApartmentInfoFields,
  getHouseInfoStep,
  getRoomsApartmentInfoFields,
  getSecurityApartmentInfoFields,
} from "kui-crm_actions"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import { ApartmentMainInfoStepFields } from "./types"
import {
  detailFieldsSettings,
  houseFieldsSettings,
  roomsFieldsSettings,
  securityFieldsSettings,
} from "./content"
import ApartmentMainInfoStepSchema from "./schema"

const ApartmentMainInfoStep = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<ApartmentMainInfoStepFields>({
    defaultValues: {
      ...apartmentInfo,
      ...formStore.fields,
    },
    // @ts-ignore
    resolver: yupResolver(ApartmentMainInfoStepSchema),
  })
  const [roomsNumber, bedroomsNumber] = useWatch({
    control: form.control,
    name: ["roomsNumber", "bedroomsNumber"],
  })
  const houseFields = getHouseInfoStep(houseFieldsSettings)
  const securityFields = getSecurityApartmentInfoFields(securityFieldsSettings)
  const roomsFields = getRoomsApartmentInfoFields(roomsFieldsSettings)
  const detailedFields = getDetailApartmentInfoFields(
    Number(roomsNumber),
    Number(bedroomsNumber),
    detailFieldsSettings,
  )
  const fields = [
    ...houseFields,
    ...securityFields,
    ...roomsFields,
    ...detailedFields,
  ]

  const handleSubmit = (data: ApartmentMainInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Проверьте и заполните остальную информацию"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(ApartmentMainInfoStep)
