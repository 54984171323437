import React from "react"
import { useForm } from "react-hook-form"
import { RegularInspectionFormFields } from "kui-crm_actions"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import FullRegularInfoStepSchema from "./schema"
import { InspectionFormProps } from "../../../types"
import RegularInspectionFields from "../../../../../components/common/InspectionFields/RegularInspectionFields"
import RoomImagesBlock from "../../../common/RoomImagesBlock"

const FullRegularInfoStep = ({ handleSubmit }: InspectionFormProps) => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<RegularInspectionFormFields>({
    defaultValues: { ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(FullRegularInfoStepSchema),
  })

  const handleFormSubmit = async (data: RegularInspectionFormFields) => {
    await handleSubmit("regular", data)
  }

  return (
    <FormWrapper
      title="Проверьте и подтвердите информацию"
      form={form}
      onSubmit={handleFormSubmit}
      onPrev={formStore.prevStep}
      label="Подтвердить"
    >
      <RegularInspectionFields form={form as any} />
      <RoomImagesBlock type="regular" form={form} roomsParams={apartmentInfo} />
    </FormWrapper>
  )
}

export default observer(FullRegularInfoStep)
