import React, { useState } from "react"
import { getFillingInfoFields } from "kui-crm_actions"
import { ApartmentFillingVariants, InputByType } from "kui-crm"
import { Button, Grid } from "kui-basic"
import { useWatch } from "react-hook-form"
import { CloseIcon } from "kui-icon"
import fillingInfoStepSettings from "../../FillingInfoStep/content"
import { FillingGroupFieldsProps } from "./types"
import ImagesBlock from "../../../../../../components/common/ImagesBlock"

const FillingGroupFields = (props: FillingGroupFieldsProps) => {
  const { index, remove, form, disabled } = props
  const [type, setType] = useState<ApartmentFillingVariants | null>(null)
  const tagName = useWatch({
    control: form.control,
    name: `fillingList.${index}.tag.name`,
  })

  const fields = getFillingInfoFields(
    true,
    type!,
    setType,
    fillingInfoStepSettings,
  )

  const handleDelete = () => {
    remove(index)
  }

  return (
    <>
      <Grid item xs={12}>
        <ImagesBlock
          withComment={false}
          label={tagName}
          form={form}
          name={`fillingList.${index}.images`}
          disabled={disabled}
          headingComponent={
            <Button
              isCircle
              size="xs"
              variant="whiteWithGray"
              onClick={handleDelete}
              disabled={disabled}
            >
              <CloseIcon width={12} height={12} />
            </Button>
          }
        />
      </Grid>
      {fields.map(({ size, name, ...field }) => (
        <Grid item xs={12} key={name}>
          <InputByType
            form={form}
            name={`fillingList.${index}.${name}`}
            disabled={disabled}
            {...field}
          />
        </Grid>
      ))}
    </>
  )
}

export default FillingGroupFields
