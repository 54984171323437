import { getPartialMeterInfoFields } from "kui-crm"
import { PartialMeterInfoFields } from "kui-crm_actions"
import commonInspectionFields from "../../../../pages/ApartmentPage/common/InspectionFirstScreen/content"
import transferTypeFields from "../../../../pages/ApartmentPage/variants/TransferInspectionForm/FullTransferInfoStep/content"
import getMeterImagesStep from "../../../../pages/ApartmentPage/common/MeterImagesStep/getMeterImagesStep"

export const getTransferFields = (meters: PartialMeterInfoFields[]) => {
  const metersFields = meters
    .map((meter: any, index: number) => [
      ...getPartialMeterInfoFields(
        meter.resource,
        meter.type,
        undefined,
        false,
        `meters.${index}`,
      ),
      getMeterImagesStep(index),
    ])
    .flat()

  return [...commonInspectionFields, ...transferTypeFields, ...metersFields]
}
