import { useContext, createContext } from "react"
import ApartmentStore from "../store/apartment/ApartmentStore"

const apartmentStore = new ApartmentStore()
const ApartmentContext = createContext(apartmentStore)

const useApartmentStore = () => useContext(ApartmentContext)

export default useApartmentStore
export { apartmentStore, ApartmentContext }
