import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { ObjectForMapOverlay, ObjectForMapParams } from "kui-crm_actions"
import { Loading } from "kui-complex"
import { theme } from "kui-basic"
import { useDebounce } from "kui-utils"
import { DynamicRoutes } from "../../../../../routes"
import { ApartmentsMapProps } from "../types"

const InspectionsMap = <T extends ObjectForMapParams>(
  props: ApartmentsMapProps<T>,
) => {
  const { apartmentsForMap, apartmentsStore, getLabel } = props
  const { actionLoader, filter, fetchAllForMap } = apartmentsStore

  const [map, setMap] = useState<google.maps.Map>()
  const mapRef = useRef<HTMLDivElement>(null)
  const centerMoscow = { lat: 55.753559, lng: 37.609218 }

  useDebounce(filter?.searchQuery || "", 500, () => {
    fetchAllForMap()
  })

  useEffect(() => {
    if (!filter.searchQuery) fetchAllForMap()
  }, [filter.searchQuery])

  useEffect(() => {
    if (mapRef.current) {
      const initialMap = new window.google.maps.Map(mapRef.current, {
        center: centerMoscow,
        zoom: 10,
        disableDefaultUI: true,
        clickableIcons: false,
      })
      setMap(initialMap)
    }
  }, [mapRef])

  return (
    <StyledWrapper>
      <StyledMapWrapper ref={mapRef} id="map">
        {apartmentsForMap.map((apartment) => (
          <ObjectForMapOverlay
            label={getLabel(apartment)}
            map={map}
            inspection={apartment}
            key={apartment.id}
            getInspectionLink={DynamicRoutes.apartment}
          />
        ))}
      </StyledMapWrapper>

      {actionLoader.isLoading && (
        <StyledBackdrop>
          <Loading height="100%" />
        </StyledBackdrop>
      )}
    </StyledWrapper>
  )
}

export default observer(InspectionsMap)

const StyledWrapper = styled.div`
  overflow: hidden;
  transition: all ease-out 0.3s;
  position: absolute;
  top: 248px;
  left: 0;
  height: calc(100vh - 248px);
  width: 100%;
  @media (min-width: ${theme.breakpoints.xs}px) {
    top: 265px;
    height: calc(100vh - 265px);
  }
  @media (min-width: ${theme.breakpoints.sm}px) {
    top: 296px;
    height: calc(100vh - 296px);
  }
  @media (max-width: 277px) {
    top: 306px;
    height: calc(100vh - 306px);
  }
`

const StyledMapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const StyledBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`
