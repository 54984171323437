import React from "react"
import styled from "@emotion/styled"
import { Button, Container, Grid, Heading } from "kui-basic"
import { CheckIcon, PrevArrowIcon } from "kui-icon"
import { useNavigate } from "react-router-dom"
import Page from "../../../../components/common/Page"

const InspectionSuccessScreen = () => {
  const navigate = useNavigate()

  const goToMain = () => {
    navigate("/")
  }

  return (
    <Page>
      <StyledContainer>
        <Grid container direction="column" alignItems="center">
          <StylesSuccessIconWrapper>
            <CheckIcon width={24} height={24} />
          </StylesSuccessIconWrapper>
          <StyledTitle size="h1">Инспекция успешно создана!</StyledTitle>
        </Grid>
        <Button
          variant="whiteWithGray"
          size="s"
          startIcon={<PrevArrowIcon />}
          onClick={goToMain}
        >
          Вернуться к списку квартир
        </Button>
      </StyledContainer>
    </Page>
  )
}

export default InspectionSuccessScreen

const StyledContainer = styled(Container)`
  margin: auto;
  height: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const StylesSuccessIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.green.ten};
  margin-bottom: 24px;
  path {
    fill: ${({ theme }) => theme.palette.green.fiftyP};
  }
`

const StyledTitle = styled(Heading)`
  text-align: center;
`
