import { makeAutoObservable } from "mobx"
import { Loader, Paginator, resHandler, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { ApartmentInspectionParams } from "kui-crm_actions"
import { DateTime } from "luxon"
import ApartmentAgent from "../agent/ApartmentAgent"
import {
  ApartmentModel,
  GetApartmentsOnMapResponse,
  GetUpcomingInspectionsResponse,
} from "../types/api/apartments"
import ApartmentsPageStore from "./ApartmentsPageStore"

class UpcomingInspectionsStore {
  value: number

  done: number

  apartments: ApartmentInspectionParams[]

  apartmentsWithInspection: ApartmentInspectionParams[]

  loader: Loader

  filter: SortingFilter

  paginator: Paginator

  actionLoader: Loader

  constructor() {
    this.apartments = []
    this.apartmentsWithInspection = []
    this.value = 0
    this.done = 0
    this.loader = new Loader(true)
    this.paginator = new Paginator(25, false)
    this.actionLoader = new Loader()
    this.filter = new SortingFilter()
    makeAutoObservable(this)
  }

  initApartmentsPage = async () => {
    this.loader.startLoading()

    await this.fetchAll(true)

    this.loader.endLoading()
  }

  fetchAllForMap = async () => {
    this.actionLoader.startLoading()

    const response = await to<GetApartmentsOnMapResponse>(
      ApartmentAgent.getInspectionsOnMap(this.filter.filterParams.slice(1)),
    )

    resHandler(response, this.actionLoader, this.updateApartmentsOnMap)
  }

  fetchAll = async (initial?: boolean) => {
    this.actionLoader.startLoading()

    const response = await to<GetUpcomingInspectionsResponse>(
      ApartmentAgent.getUpcomingInspections(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams,
      ),
    )

    resHandler(response, this.actionLoader, (res) =>
      this.updateApartments(res, initial),
    )
  }

  updateApartmentsOnMap = (res: GetApartmentsOnMapResponse) => {
    this.apartmentsWithInspection = res.results.map((apartment) =>
      UpcomingInspectionsStore.getApartmentParams(apartment),
    )
  }

  updateApartments = (
    res: GetUpcomingInspectionsResponse,
    isInitial?: boolean,
  ) => {
    if (isInitial) {
      this.value = res.upcoming_count
      this.done = res.completed_count
    }

    const mapper = (apartment: ApartmentModel) =>
      UpcomingInspectionsStore.getApartmentParams(apartment)

    this.apartments = this.paginator.getPageResponse<
      ApartmentModel,
      ApartmentInspectionParams
    >(res, this.apartments, mapper)
  }

  static getApartmentParams = (
    object: ApartmentModel,
  ): ApartmentInspectionParams => {
    const { action_date, is_upcoming, apartment } = object

    return {
      ...ApartmentsPageStore.getApartmentParams(apartment),
      isInspectionUpcoming: is_upcoming,
      date: action_date ? DateTime.fromISO(action_date) : null,
    }
  }
}

export default UpcomingInspectionsStore
