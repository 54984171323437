import React from "react"
import styled from "@emotion/styled"
import { Heading } from "kui-basic"
import { TitleProps } from "./types"

const Title = ({ title }: TitleProps) => <StyledHeading>{title}</StyledHeading>

export default Title

const StyledHeading = styled(Heading)`
  padding: 14px 0;
`
