import React from "react"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import useInspectionStore from "../../store"
import inspectionFields from "./content"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import { InspectionFields } from "../../../../components/common/InspectionFields/types"
import RoomImagesBlock from "../RoomImagesBlock"

const InspectionForm = () => {
  const { apartmentInfo } = useApartmentStore()
  const { fields, type } = useInspectionStore()
  const form = useForm<InspectionFields>({
    defaultValues: { ...fields },
  })

  if (!type) return null

  const InspectionFormFields =
    inspectionFields[type as keyof typeof inspectionFields]

  return (
    <form>
      <StyledContentWrapper>
        <InspectionFormFields
          disabled
          form={form}
          roomsParams={apartmentInfo}
        />
        <RoomImagesBlock form={form} />
      </StyledContentWrapper>
    </form>
  )
}

export default InspectionForm

const StyledContentWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`
