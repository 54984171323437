import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { ContractAccordionProps } from "./types"
import LinesWithAccordion from "../LinesWithAccordion"
import useApartmentStore from "../../../../../hooks/useApartmentStore"

const ContractAccordion = (props: ContractAccordionProps) => {
  const { inspectionsStore, index } = props
  const { apartmentInfo } = useApartmentStore()
  const { loader, inspections, fetchContractInspections, contract, paginator } =
    inspectionsStore

  useEffect(() => {
    if (paginator.isFetching && !paginator.isFinishPage && apartmentInfo?.id) {
      fetchContractInspections(apartmentInfo.id)
    }
    if (paginator.isFetching && paginator.isFinishPage) {
      paginator.stopFetching()
    }
  }, [paginator, paginator.isFetching])

  const handleAccordionOpen = (expanded: boolean) => {
    if (expanded && index !== 0) {
      paginator.startFetching()
    }
  }

  useEffect(() => {
    if (index === 0) {
      paginator.startFetching()
    }

    return () => paginator.refresh()
  }, [])

  return (
    <LinesWithAccordion
      label={`По контракту найма №${contract.number}`}
      handleAccordionOpen={handleAccordionOpen}
      index={index}
      inspections={inspections}
      loader={loader}
      paginator={paginator}
      expanded={index === 0}
    />
  )
}

export default observer(ContractAccordion)
