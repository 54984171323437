import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { useForm } from "react-hook-form"
import {
  getHouseServicesInfoStep,
  getTelecommunicationsInfoStepFields,
} from "kui-crm_actions"
import { yupResolver } from "@hookform/resolvers/yup"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import InventoryMainInfoStepSchema from "./schema"
import { InventoryMainInfoStepFields } from "./types"
import {
  houseServicesFieldsSettings,
  telecommunicationFieldsSettings,
} from "./content"

const InventoryMainInfoStep = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<InventoryMainInfoStepFields>({
    defaultValues: { ...apartmentInfo, ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(InventoryMainInfoStepSchema),
  })
  const houseServicesFields = getHouseServicesInfoStep(
    houseServicesFieldsSettings,
  )
  const telecommunicationFields = getTelecommunicationsInfoStepFields(
    telecommunicationFieldsSettings,
  )
  const fields = [...houseServicesFields, ...telecommunicationFields]

  const handleSubmit = (data: InventoryMainInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Заполните информацию"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(InventoryMainInfoStep)
