import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import LinesWithAccordion from "./LinesWithAccordion"
import ContractAccordion from "./ContractAccordion"

const InspectionsList = () => {
  const { inspectionsStore } = useApartmentStore()
  const { inspectionsByContract, apartmentInspections, loader, paginator } =
    inspectionsStore

  useEffect(() => {
    inspectionsStore.fetchApartmentInspections()
  }, [])

  useEffect(
    () => () => {
      paginator.refresh()
      loader.startLoading()
    },
    [],
  )

  if (loader.isLoading && paginator.offset < paginator.limit)
    return <Loading height="50vh" />

  return (
    <>
      {!!apartmentInspections?.length && (
        <LinesWithAccordion
          loader={loader}
          paginator={paginator}
          inspections={apartmentInspections}
          label="Между контрактами"
          expanded={!inspectionsByContract.length}
        />
      )}
      {inspectionsByContract.map((store, index) => (
        <ContractAccordion
          key={`${store.contract.number}_${index}`}
          inspectionsStore={store}
          index={index}
        />
      ))}
    </>
  )
}

export default observer(InspectionsList)
