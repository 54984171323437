import * as yup from "yup"
import {
  AdditionalInfoFieldsSchema,
  FillingInfoFieldsSchema,
  HouseServicesInfoFieldsSchema,
  MetersInfoFieldsSchema,
  TelecommunicationsInfoFieldsSchema,
} from "kui-crm_actions"
import FullMeterInfoStepSchema from "../FullMeterInfoStep/schema"

const FullInventoryInfoStepSchema = yup.object().shape(
  {
    ...HouseServicesInfoFieldsSchema,
    ...TelecommunicationsInfoFieldsSchema,
    ...AdditionalInfoFieldsSchema,
    ...MetersInfoFieldsSchema,
    meters: yup.array().of(FullMeterInfoStepSchema),
    fillingList: yup.array().of(FillingInfoFieldsSchema),
  },
  [["homePhone", "homePhone"]],
)

export default FullInventoryInfoStepSchema
