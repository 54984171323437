import React from "react"
import { Loading } from "kui-complex"
import { observer } from "mobx-react"
import { useDebounce } from "kui-utils"
import useStore from "../../../../hooks/useStore"
import ObjectNeedInitialCard from "../ObjectNeedInitialCard"

const ObjectsNeedInitialList = () => {
  const { apartmentsPageStore } = useStore()
  const { actionLoader, objects, fetchAll, filter } =
    apartmentsPageStore.objectsNeedInitialStore

  useDebounce(filter.searchQuery || "", 500, () => {
    fetchAll()
  })

  if (actionLoader.isLoading) return <Loading height="95vh" />

  return (
    <div>
      {objects.map((object) => (
        <ObjectNeedInitialCard key={object.id} object={object} />
      ))}
    </div>
  )
}

export default observer(ObjectsNeedInitialList)
