export const additionalInfoFieldsSettings = {
  withPets: { label: "Можно с животными" },
  withChildren: { label: "Можно с детьми" },
  additionalTerms: { placeholder: "другие условия" },
  payerCode: { label: "Код плательщика" },
  communalServicesAccount: { label: "Номер лицевого счета" },
  company: { label: "Управляющая компания" },
  countryOfResidence: { label: "Страна резиденства " },
  taxesType: { label: "Тип налогооблажения" },
}
