export const houseServicesFieldsSettings = {
  hasBarrier: { label: "Шлагбаум" },
  barrierPhone: { label: "Телефон шлагбаума" },
  hasConcierge: { label: "Консъерж" },
  conciergePhone: { label: "Телефон консьержа" },
  hasSecurity: { label: "Охрана" },
  securityPhone: { label: "Номер охраняющей компании" },
  hasVideoControl: { label: "Видеонаблюдение" },
  videoControlPhone: { label: "Номер компании видеонаблюдения" },
  passengerElevatorsCount: { label: "Пассажирские лифты" },
  serviceElevatorsCount: { label: "Грузовые лифты" },
}

export const telecommunicationFieldsSettings = {
  wifi: {
    name: { label: "Имя сети" },
    password: { label: "Пароль от WiFi" },
  },
  internetProvider: {
    link: { label: "Ссылка на провайдера" },
    login: { label: "Логин кабинета" },
    password: { label: "Пароль от кабинета" },
  },
  tvType: { label: "Телевидение" },
  homePhone: { label: "Домашний телефон" },
}
