import React from "react"
import { Grid } from "kui-basic"
import { FillingCheckParams, InspectionFillingCard } from "kui-crm_actions"
import { observer } from "mobx-react"
import { useWatch } from "react-hook-form"
import FieldsHeading from "../../../../components/common/FieldsHeading"
import { fillingWarningMessage } from "../../../../utils/constants"
import { InspectionCheckFillingBlockProps } from "./types"

const InspectionCheckFillingBlock = (
  props: InspectionCheckFillingBlockProps,
) => {
  const { form, disabled } = props
  const fillingList = useWatch({
    control: form.control,
    name: "fillingList",
  })

  return (
    <>
      <FieldsHeading title="Наполнение" />
      <Grid container spacing={2}>
        {fillingList.map((filling: FillingCheckParams, index: number) => (
          <Grid item xs={12} key={filling.id}>
            <InspectionFillingCard
              disabled={disabled}
              filling={filling}
              index={index}
              form={form}
              warningLabel={fillingWarningMessage}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default observer(InspectionCheckFillingBlock)
