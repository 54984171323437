import React from "react"
import styled from "@emotion/styled"
import { HomeIcon } from "kui-icon"
import { Caption } from "kui-basic"
import { observer } from "mobx-react"
import { declineByNumber } from "kui-utils"
import Card from "../../../../components/common/Card"
import { InspectionsCountCardProps } from "./types"

const InspectionsCountCard = (props: InspectionsCountCardProps) => {
  const { apartmentsStore } = props
  const { value, done } = apartmentsStore
  const valueLabels = [
    "осмотр запланирован",
    "осмотра запланировано",
    "осмотров запланировано",
  ]
  const doneLabels = [
    "осмотр проведен",
    "осмотра проведено",
    "осмотров проведено",
  ]
  const valueLabel = declineByNumber(value, valueLabels, true)
  const doneLabel = declineByNumber(done, doneLabels, true)

  return (
    <StyledCard>
      <StyledIconWrapper>
        <HomeIcon width={20} height={20} />
      </StyledIconWrapper>
      <div>
        <Caption size="xs" weight={500}>
          <StyledPlanAccent>{value}</StyledPlanAccent> {valueLabel}
        </Caption>
        <Caption size="xs" weight={500}>
          <StyledDoneAccent>{done}</StyledDoneAccent> {doneLabel}
        </Caption>
      </div>
    </StyledCard>
  )
}

export default observer(InspectionsCountCard)

const StyledCard = styled(Card)`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background: ${({ theme }) => theme.palette.brand.background};
  width: 40px;
  height: 40px;
  border-radius: 8px;

  path {
    fill: ${({ theme }) => theme.palette.brand.main};
  }
`

const StyledPlanAccent = styled.span`
  color: ${({ theme }) => theme.palette.brand.main};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
`

const StyledDoneAccent = styled.span`
  color: ${({ theme }) => theme.palette.green.fiftyP};
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
`
