import React from "react"
import styled from "@emotion/styled"
import { LoaderState, ScrollingContainer, AccordionWithSummary } from "kui-crm"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import { LinesWithAccordionProps } from "./types"
import InspectionRow from "../InspectionRow"

const LinesWithAccordion = (props: LinesWithAccordionProps) => {
  const {
    paginator,
    loader,
    inspections,
    expanded,
    label,
    handleAccordionOpen,
  } = props

  return (
    <AccordionWithSummary
      title={label}
      onChange={handleAccordionOpen}
      expanded={expanded}
    >
      <StyledAccordionContent onScrollEnd={paginator.startFetching}>
        <div>
          {inspections?.map((inspection) => (
            <InspectionRow key={inspection.id} inspection={inspection} />
          ))}
        </div>
        {!!(loader.isLoading && inspections.length) && (
          <Loading height="72px" />
        )}
        <LoaderState loader={loader} onlyError />
      </StyledAccordionContent>
    </AccordionWithSummary>
  )
}

export default observer(LinesWithAccordion)

const StyledAccordionContent = styled(ScrollingContainer)`
  padding-top: 2px;
  max-height: 288px;
`
