import React from "react"
import { Grid } from "kui-basic"
import FieldsHeading from "../../../../components/common/FieldsHeading"
import { RoomImagesBlockProps } from "./types"
import getRoomsImagesSteps from "../InspectionImagesStep/imagesSteps/steps"
import ImagesBlock from "../../../../components/common/ImagesBlock"

const RoomImagesBlock = (props: RoomImagesBlockProps) => {
  const { form, roomsParams, type, disabled } = props
  const fields = getRoomsImagesSteps(type, roomsParams)

  return (
    <>
      <FieldsHeading title="Фотографии" />
      {fields.map((field, index) => (
        <Grid item xs={12}>
          <ImagesBlock
            label={field.title}
            form={form}
            name={`roomsImages.${index}`}
            disabled={disabled}
          />
        </Grid>
      ))}
    </>
  )
}

export default RoomImagesBlock
