import { InputParams, LinkField } from "kui-crm"

const commonInspectionFields: InputParams<any>[] = [
  {
    label: "Ссылка на Dropbox",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default commonInspectionFields
