import React from "react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { MeterFieldsProps } from "./types"
import useMeterInfoFields from "../../FullMeterInfoStep/useMeterInfoFields"
import meterLabels from "../../../../common/PartialMeterInfoStep/content"
import FieldsHeading from "../../../../../../components/common/FieldsHeading"
import ImagesBlock from "../../../../../../components/common/ImagesBlock"

const MeterFields = (props: MeterFieldsProps) => {
  const { resource, index, form, disabled } = props

  const fields = useMeterInfoFields({ resource, index, form })

  return (
    <Grid item xs={12} container spacing={2}>
      <FieldsHeading title={`Счетчик ${meterLabels[resource]}`} />
      {fields.map(({ name, ...field }) => (
        <Grid item key={name} xs={12}>
          <InputByType
            disabled={disabled}
            form={form}
            name={`meters.${index}.${name}`}
            {...field}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <ImagesBlock
          disabled={disabled}
          form={form}
          name={`meters.${index}.images`}
        />
      </Grid>
    </Grid>
  )
}

export default observer(MeterFields)
