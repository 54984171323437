import React from "react"
import { Box, Button, Grid, Heading } from "kui-basic"
import { MapIcon, TableIcon } from "kui-icon"
import { ApartmentsPageTitleProps } from "./types"
import InspectionsCountCard from "../InspectionsCountCard"

const ApartmentsPageTitle = (props: ApartmentsPageTitleProps) => {
  const { setView, view, apartmentsStore } = props

  const toggleView = () => {
    if (view === "list") setView("map")
    else setView("list")
  }

  return (
    <>
      <Box mt={2} mb={2}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Heading size="h1">Выберите объект</Heading>
          <Button isCircle size="xs" variant="orange" onClick={toggleView}>
            {view === "list" ? (
              <MapIcon width={14} height={14} />
            ) : (
              <TableIcon width={14} height={14} />
            )}
          </Button>
        </Grid>
      </Box>
      <InspectionsCountCard apartmentsStore={apartmentsStore} />
    </>
  )
}

export default ApartmentsPageTitle
