import { InspectionTypes } from "kui-crm"

export type InspectionTypeOption = {
  type: InspectionTypes
  disabled: boolean
}

export const getInspectionTypes = (
  availableInspectionTypes: InspectionTypes[],
): InspectionTypeOption[] =>
  ["initial", "inventory", "transfer", "regular"].map(
    (type) =>
      ({
        type,
        disabled: !availableInspectionTypes.includes(type as InspectionTypes),
      }) as InspectionTypeOption,
  )
