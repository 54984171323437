import React, { SyntheticEvent } from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import { FileStore } from "kui-crm"
import { UploadButtonProps } from "./types"

const UploadButton = (props: UploadButtonProps) => {
  const { append, filesStore, fieldsLength } = props

  const appendImage = (e: SyntheticEvent<HTMLInputElement>) => {
    const uploadedFiles = (e.target as HTMLInputElement).files || []
    for (let i = 0; i < uploadedFiles.length; i += 1) {
      const file = uploadedFiles[i]
      const newFile = FileStore.initFromFile(file, fieldsLength + i)
      append(newFile)
      filesStore.addFile(newFile)
    }
  }

  return (
    <Grid item xs={4}>
      <StyledLabel>
        <StyledInput multiple type="file" onChange={appendImage} />

        <PlusIcon width={24} height={24} />
      </StyledLabel>
    </Grid>
  )
}

export default UploadButton

const StyledInput = styled.input`
  display: none;
`

const StyledLabel = styled.label`
  border-radius: 8px;
  width: 100%;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`
