import React from "react"
import { Grid } from "kui-basic"
import {
  InputByType,
  MeterFeaturesFormFields,
  MeterTypes,
  ResourceTypes,
} from "kui-crm"
import { AddedMetersBlock } from "kui-crm_actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import FormWrapper from "../../../../../components/common/FormWrapper"
import { MeterFormFieldsProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import meterLabels, {
  meterTypes,
} from "../../../common/PartialMeterInfoStep/content"
import useMeterInfoFields from "./useMeterInfoFields"
import FullMeterInfoStepSchema from "./schema"

const FullMeterInfoStep = (props: MeterFormFieldsProps) => {
  const { resource, index } = props
  const { apartmentInfo, inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const defaultMetersValues = formStore.fields?.meters?.[index]
  const type = defaultMetersValues?.type as MeterTypes
  const label = `Введите данные счетчика ${type ? `${meterTypes[type]} ` : ""}${
    meterLabels[resource]
  }`

  const form = useForm<MeterFeaturesFormFields>({
    defaultValues: {
      communalServicesAccount: apartmentInfo?.communalServicesAccount,
      resource,
      ...defaultMetersValues,
    },
    // @ts-ignore
    resolver: yupResolver(FullMeterInfoStepSchema),
  })

  const meterFields = useMeterInfoFields({ form, resource, index })

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    formStore.replaceFieldInArray("meters", { ...data, resource }, index)
    formStore.nextStep()
  }

  const getMeterLabel = (
    meterResource: ResourceTypes,
    meterType?: MeterTypes,
  ) =>
    `Счетчик ${meterType ? `${meterTypes[meterType]} ` : ""}${
      meterLabels[meterResource]
    }`

  return (
    <FormWrapper
      title={label}
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddedMetersBlock
            formStore={formStore}
            resource={resource}
            label="В предыдущих шагах были добавлены:"
            getMeterLabel={getMeterLabel}
          />
        </Grid>
        {meterFields.map(({ size, ...field }) => (
          <Grid item key={field.name} xs={12}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(FullMeterInfoStep)
