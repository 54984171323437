import { observer } from "mobx-react"
import * as React from "react"
import styled from "@emotion/styled"
import { theme } from "kui-basic"
import { Loading } from "kui-complex"
import { LoaderState } from "kui-crm"
import { PageProps } from "./types"
import Header from "../Header"

function Page(props: PageProps) {
  const { children, className, loader } = props

  return (
    <StyledWrapper>
      <Header />
      <ContentWrapper className={className}>
        {loader?.isLoading ? <Loading height="calc(100vh - 49px)" /> : children}
      </ContentWrapper>
      <LoaderState loader={loader} onlyError />
    </StyledWrapper>
  )
}

export default observer(Page)

const StyledWrapper = styled.div`
  min-height: 100vh;
`

const ContentWrapper = styled.div`
  min-height: 100vh;
  padding-top: 80px;
  background: ${theme.palette.background.light2};
  display: flex;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.sm}px) {
    padding-top: 65px;
  }
  @media (max-width: ${theme.breakpoints.xs}px) {
    padding-top: 49px;
  }
`
