import * as React from "react"
import { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useLocation } from "react-router"
import { Button, theme } from "kui-basic"
import { BurgerIcon, CloseIcon } from "kui-icon"
import MenuLink from "./MenuLink"
import { HideMenuContentProps } from "./types"

const HideMenuContent = ({
  isOpen,
  setIsOpen,
  links,
}: HideMenuContentProps) => {
  const location = useLocation()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (dropdownRef.current && buttonRef.current) {
        if (
          !dropdownRef.current.contains(e.target) &&
          !buttonRef.current.parentElement?.contains(e.target) &&
          e.target.dataset.role !== "burger" &&
          e.target.parentElement?.dataset.role !== "burger"
        ) {
          handleClose()
        }
      }
    }

    document.addEventListener("click", closeDropdown)
    return () => {
      document.removeEventListener("click", closeDropdown)
    }
  }, [buttonRef, dropdownRef])

  return (
    <Wrapper>
      <Button
        ref={buttonRef}
        size="xs"
        variant="transparent"
        onClick={handleClick}
        isCircle
      >
        {isOpen ? <CloseIcon /> : <BurgerIcon data-role="burger" />}
      </Button>

      {isOpen ? (
        <Content ref={dropdownRef}>
          <hr />
          {links.map((route) => (
            <MenuLink
              link={route.link}
              key={route.link}
              title={route.title}
              isActive={
                route.link.length > 1
                  ? location.pathname
                      .replace(/\//g, "")
                      .includes(route.link.slice(1))
                  : location.pathname === route.link ||
                    location.pathname.includes("main")
              }
            />
          ))}
        </Content>
      ) : null}
    </Wrapper>
  )
}

export default HideMenuContent

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  height: 48px;
  z-index: 1001;
  & hr {
    margin: 0 0 8px;
    border-bottom: 1px solid ${theme.palette.background.light3};
    border-top: 0;
  },
`

const Content = styled.div`
  padding: 0 0 8px;
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  background: ${theme.palette.grey.zero};
`
