import React from "react"
import { Button, Grid, Heading } from "kui-basic"
import { PlusIcon } from "kui-icon"
import { HeadingWithButtonProps } from "./types"

const HeadingWithButton = (props: HeadingWithButtonProps) => {
  const { title, onClick, disabled } = props

  return (
    <Grid
      item
      xs={12}
      container
      justify="space-between"
      wrap="nowrap"
      alignItems="center"
    >
      <Heading size="h3">{title}</Heading>
      <Button
        disabled={disabled}
        isCircle
        size="xs"
        variant="whiteWithGray"
        onClick={onClick}
      >
        <PlusIcon />
      </Button>
    </Grid>
  )
}

export default HeadingWithButton
