import React from "react"
import { Heading } from "kui-basic"
import styled from "@emotion/styled"
import { SquarePreviewImage } from "kui-crm"
import { useNavigate } from "react-router-dom"
import { ApartmentCardProps } from "./types"
import { DynamicRoutes } from "../../../../routes"
import Card from "../../../../components/common/Card"
import DateWithDot from "../../../../components/common/DateWithDot"

const UpcomingInspectionCard = ({ apartment }: ApartmentCardProps) => {
  const navigate = useNavigate()

  const goToApartment = () => {
    navigate(DynamicRoutes.apartment(apartment.id))
  }

  return (
    <StyledWrapper onClick={goToApartment}>
      <StyledImage size="sm" imageSrc={apartment.apartment.previewImage} />
      <div>
        <StyledAddress size="h3" weight={700}>
          {apartment.apartment.address}
        </StyledAddress>
        <DateWithDot
          withDot={apartment.isInspectionUpcoming}
          date={apartment.date}
        />
      </div>
    </StyledWrapper>
  )
}

export default UpcomingInspectionCard

const StyledWrapper = styled(Card)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const StyledImage = styled(SquarePreviewImage)`
  margin-right: 10px;
  min-width: 40px;
  min-height: 40px;
`

const StyledAddress = styled(Heading)`
  margin-bottom: 2px;
`
