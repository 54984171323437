import React from "react"
import styled from "@emotion/styled"
import { Container } from "kui-basic"
import { observer } from "mobx-react"
import { defaultApartmentImage } from "../../../../../utils/constants"
import ApartmentAddressCard from "./ApartmentAddressCard"
import ApartmentInfoCard from "./ApartmentInfoCard"
import useApartmentStore from "../../../../../hooks/useApartmentStore"

const ApartmentInfoBlock = () => {
  const { apartmentInfo } = useApartmentStore()

  return (
    <StyledWrapper>
      <StyledBackgroundImage
        src={apartmentInfo?.imageUrl || defaultApartmentImage}
      />

      <StyledContainer>
        <ApartmentAddressCard />
        <ApartmentInfoCard />
      </StyledContainer>
    </StyledWrapper>
  )
}

export default observer(ApartmentInfoBlock)

const StyledWrapper = styled.div`
  width: 100%;
  padding: 16px 0;
  position: relative;
  overflow: hidden;
`

const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  filter: blur(8px);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledContainer = styled(Container)`
  position: relative;
`
