import React from "react"
import { InspectionImagesStepProps } from "./types"
import InspectionImagesStep from "../../../common/InspectionImagesStep"

const FillingImagesStep = (props: InspectionImagesStepProps) => {
  const { addStep, index } = props

  return (
    <InspectionImagesStep
      withComment={false}
      label="Загрузите фотографии наполнения"
      entityName={`fillingList.${index}`}
      name="images"
      actionLabel="Еще одно наполнение"
      actionHandler={addStep}
    />
  )
}

export default FillingImagesStep
