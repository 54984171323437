import React from "react"
import { observer } from "mobx-react"
import { MapWrapper } from "kui-complex"
import { ObjectForMapParams } from "kui-crm_actions"
import InspectionsMap from "./InspectionsMap"
import { ApartmentsMapProps } from "./types"

const ApartmentsMap = <T extends ObjectForMapParams>(
  props: ApartmentsMapProps<T>,
) => (
  <MapWrapper>
    <InspectionsMap {...props} />
  </MapWrapper>
)

export default observer(ApartmentsMap)
