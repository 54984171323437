import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import { PartialMeterInfoFields } from "kui-crm_actions"
import { DateTime } from "luxon"
import to from "await-to-js"
import {
  ResourceTypes,
  ApartmentMetersAgent,
  MetersResponse,
  MeterModel,
  SingleMeterParams,
  ElectricityMeterParams,
  FileStore,
} from "kui-crm"
import ApartmentStore from "./ApartmentStore"

class ApartmentMetersStore {
  waterMeters: PartialMeterInfoFields[]

  electricityMeters: PartialMeterInfoFields[]

  gasMeters: PartialMeterInfoFields[]

  heatingMeters: PartialMeterInfoFields[]

  apartmentStore: ApartmentStore

  loader: Loader

  constructor(apartmentStore: ApartmentStore) {
    this.waterMeters = []
    this.electricityMeters = []
    this.gasMeters = []
    this.heatingMeters = []
    this.loader = new Loader(true)
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  fetchMeters = async () => {
    this.loader.startLoading("apartment meters")

    await Promise.allSettled([
      this.fetchMetersByResource("water"),
      this.fetchMetersByResource("electricity"),
      this.fetchMetersByResource("gas"),
      this.fetchMetersByResource("heating"),
    ])

    this.loader.endLoading()
  }

  fetchMetersByResource = async (resource: ResourceTypes) => {
    const apartmentId = this.apartmentStore.apartmentInfo?.id
    const rcId = this.apartmentStore.apartmentInfo?.actualRentalContractId

    if (apartmentId) {
      const currentDate = DateTime.now()
      const response = await to<MetersResponse>(
        ApartmentMetersAgent.getMeters(
          apartmentId,
          resource,
          currentDate.year,
          currentDate.month,
          rcId,
          "&archived=false",
        ),
      )

      resHandler(
        response,
        this.loader,
        (res) => this.updateMeters(resource, res),
        "apartment meters",
        { withEndLoading: false },
      )
    }
  }

  updateMeters = (resource: ResourceTypes, data: MetersResponse) => {
    this[`${resource}Meters`] = data.meters.map((meter) =>
      ApartmentMetersStore.getMeterValuesParams(meter, resource),
    )
  }

  get meters() {
    return [
      ...this.waterMeters,
      ...this.electricityMeters,
      ...this.gasMeters,
      ...this.heatingMeters,
    ]
  }

  static getMeterValuesParams = (
    meter: MeterModel,
    resource: ResourceTypes,
  ): PartialMeterInfoFields => ({
    id: meter.id,
    resource,
    type: meter.type,
    number: meter.passport_number,
    images:
      meter.photos?.map((image) => FileStore.initFromImageModel(image)) || [],
    maxValue: ApartmentMetersStore.getMaxMeterValues(meter, resource),
    values: ApartmentMetersStore.getMeterValues(meter, resource),
  })

  static getMeterValues = (meter: MeterModel, resource: ResourceTypes) => {
    if (resource === "electricity") {
      const electricityMeter = meter as ElectricityMeterParams
      return {
        T1: electricityMeter.values.T1.previous_value,
        T2: electricityMeter.values.T2?.previous_value,
        T3: electricityMeter.values.T3?.previous_value,
      }
    }
    const singleMeter = meter as SingleMeterParams
    return { value: singleMeter.previous_value }
  }

  static getMaxMeterValues = (meter: MeterModel, resource: ResourceTypes) => {
    if (resource === "electricity") {
      const electricityMeter = meter as ElectricityMeterParams
      return {
        T1: electricityMeter.values.T1.max_possible_value,
        T2: electricityMeter.values.T2?.max_possible_value,
        T3: electricityMeter.values.T3?.max_possible_value,
      }
    }
    const singleMeter = meter as SingleMeterParams
    return { value: singleMeter.max_possible_value }
  }
}

export default ApartmentMetersStore
