export const securityFieldsSettings = {
  typeOfObject: { label: "Тип объекта" },
  entrance: { label: "Подъезд" },
  hasIntercom: { label: "Домофон" },
  intercomCode: { label: "Код домофона" },
  hasGate: { label: "Ворота" },
  gateCode: { label: "Код ворот" },
  parkingType: { label: "Парковка" },
  parkingNumber: { label: "Номер парковки" },
}

export const houseFieldsSettings = {
  typeOfHouse: { label: "Тип дома" },
  buildingYear: { label: "Год постройки" },
  wallMaterial: { label: "Материал стен" },
  overlapType: { label: "Тип перекрытий" },
  passengerElevatorsCount: { label: "Количество пассажирских лифтов" },
  serviceElevatorsCount: { label: "Количество грузовых лифтов" },
  houseNumber: { label: "Серия дома" },
  gasLeads: { label: "Подключение газа" },
  withHotWater: { label: "Холодная вода" },
}

export const roomsFieldsSettings = {
  floor: { label: "Этаж" },
  floorCount: { label: "Этажей в доме" },
  roomsNumber: { label: "Количество комнат" },
  bedroomsNumber: { label: "Количество спален" },
  bathroomsNumber: { label: "Количество ванных" },
  restroomsNumber: { label: "Количество туалетов" },
  combinedBathroomsNumber: { label: "Количество совмещенных санузлов" },
}

export const detailFieldsSettings = {
  area: { label: "Общая площадь" },
  livingArea: { label: "Жилая площадь" },
  kitchenArea: { label: "Площадь кухни" },
  rooms: (index: number) => ({
    area: { label: `Площадь комнаты ${index + 1}` },
    type: { label: `Тип комнаты ${index + 1}` },
  }),
  bedrooms: (index: number) => ({
    area: { label: `Площадь спальни ${index + 1}` },
    type: { label: `Тип спальни ${index + 1}` },
  }),
  ceilingHeight: { label: "Высота потолков" },
  numberOfWindows: { label: "Количество окон" },
  renovation: {
    year: { label: "Год последнего ремонта" },
    type: { label: "Тип ремонта" },
    style: { label: "Стиль ремонта" },
  },
}
