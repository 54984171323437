import React from "react"
import styled from "@emotion/styled"
import { theme } from "kui-basic"
import { InspectionFeedbackStatusProps } from "./types"
import inspectionFeedbackLabels from "./content"
import InspectionFeedbackIndicator from "../InspectionFeedbackIndicator"

const InspectionFeedbackStatus = (props: InspectionFeedbackStatusProps) => {
  const { inspection } = props
  const { landlordFeedback, tenantFeedback } = inspection

  if (!landlordFeedback && !tenantFeedback) return null
  const feedback = (tenantFeedback || landlordFeedback)!

  return (
    <StyledWrapper>
      <InspectionFeedbackIndicator
        feedback={feedback}
        labels={inspectionFeedbackLabels}
      />
    </StyledWrapper>
  )
}

export default InspectionFeedbackStatus

const StyledWrapper = styled.div`
  margin-right: 16px;
  @media (max-width: ${theme.breakpoints.sm}px) {
    margin-right: 8px;
  }
`
