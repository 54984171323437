import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { useForm } from "react-hook-form"
import {
  AdditionalInfoStepFields,
  AdditionalInfoStepSchema,
  getAdditionalInfoStepFields,
} from "kui-crm_actions"
import { yupResolver } from "@hookform/resolvers/yup"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../../components/common/FormWrapper"
import { additionalInfoFieldsSettings } from "./content"

const InventoryAdditionalInfoStep = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<AdditionalInfoStepFields>({
    defaultValues: { ...apartmentInfo, ...formStore.fields },
    // @ts-ignore
    resolver: yupResolver(AdditionalInfoStepSchema),
  })

  const fields = getAdditionalInfoStepFields(additionalInfoFieldsSettings)

  const handleSubmit = (data: AdditionalInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title="Запросите у собственника информацию"
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(InventoryAdditionalInfoStep)
