import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const FullRegularInfoStepSchema = yup.object().shape({
  meters: yup.array().of(
    yup.object({
      number: yup.string().required(defaultRequiredMessage),
    }),
  ),
})

export default FullRegularInfoStepSchema
