import React from "react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { useFieldArray } from "react-hook-form"
import { getTransferFields } from "./content"
import { InspectionFieldsProps } from "../types"
import InspectionCheckFillingBlock from "../../../../pages/ApartmentPage/common/InspectionCheckFillingBlock"

const TransferInspectionFields = (props: InspectionFieldsProps) => {
  const { form, disabled } = props
  const { fields: meterFields } = useFieldArray({
    control: form.control,
    name: "meters",
  })
  // @ts-ignore
  const fields = getTransferFields(meterFields)

  return (
    <>
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={12} key={field.name}>
            <InputByType disabled={disabled} form={form} {...field} />
          </Grid>
        ))}
      </Grid>
      <InspectionCheckFillingBlock disabled={disabled} form={form} />
    </>
  )
}

export default TransferInspectionFields
