import React from "react"
import { Tooltip } from "kui-crm"
import styled from "@emotion/styled"
import {
  CheckWithCircleIcon,
  CrossWithCircleIcon,
  QuestionIcon,
} from "kui-icon"
import { theme } from "kui-basic"
import InspectionFeedbackComment from "./InspectionFeedbackComment"
import {
  FeedbackIconStylesProps,
  InspectionFeedbackIndicatorProps,
} from "./types"

const InspectionFeedbackIndicator = (
  props: InspectionFeedbackIndicatorProps,
) => {
  const { feedback, className, labels } = props

  return (
    <div className={className}>
      <Tooltip
        content={
          <InspectionFeedbackComment feedback={feedback} labels={labels} />
        }
      >
        <StyledIconWrapper status={feedback.status}>
          {indicatorIcon[feedback.status]}
        </StyledIconWrapper>
      </Tooltip>
    </div>
  )
}

export default InspectionFeedbackIndicator

const StyledQuestionIcon = styled(QuestionIcon)`
  path {
    fill: ${theme.palette.grey.fiftyP};
  }
`

const StyledCheckIcon = styled(CheckWithCircleIcon)`
  path {
    fill: ${theme.palette.green.fiftyP};
  }
`

const StyledErrorIcon = styled(CrossWithCircleIcon)`
  width: 14px;
  height: 14px;
  path {
    fill: ${theme.palette.red.fiftyP};
  }
`

const indicatorColor = {
  notSigned: theme.palette.background.light1,
  signed: theme.palette.green.five,
  rejected: theme.palette.red.five,
}

const StyledIconWrapper = styled.div<FeedbackIconStylesProps>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ status }) => indicatorColor[status]};
`

const indicatorIcon = {
  notSigned: <StyledQuestionIcon />,
  signed: <StyledCheckIcon />,
  rejected: <StyledErrorIcon />,
}
