import InitialInspectionFields from "../../../../components/common/InspectionFields/InitialInspectionFields"
import InventoryInspectionFields from "../../../../components/common/InspectionFields/InventoryInspectionFields"
import TransferInspectionFields from "../../../../components/common/InspectionFields/TransferInspectionFields"
import RegularInspectionFields from "../../../../components/common/InspectionFields/RegularInspectionFields"

const inspectionFields = {
  initial: InitialInspectionFields,
  inventory: InventoryInspectionFields,
  transfer: TransferInspectionFields,
  regular: RegularInspectionFields,
}

export default inspectionFields
