import React from "react"
import { AdaptiveImagesBlock } from "kui-crm"
import { Caption, Grid } from "kui-basic"
import { useFieldArray } from "react-hook-form"
import { InputWithController } from "kui-complex"
import styled from "@emotion/styled"
import { ImagesBlockProps } from "./types"
import { UploadImageFields } from "../UploadImages/types"

const ImagesBlock = (props: ImagesBlockProps) => {
  const {
    form,
    label,
    name,
    subName,
    headingComponent,
    withComment,
    disabled,
  } = props
  const { fields, remove } = useFieldArray({
    control: form.control,
    name: withComment ? `${name}.${subName || "files"}` : name,
  })

  const formattedFields = (fields as UploadImageFields[]).map((field) => {
    const url =
      field.file instanceof File ? URL.createObjectURL(field.file) : field.url

    return {
      smallImageUrl: url || "",
      mediumImageUrl: url || "",
      largeImageUrl: url || "",
    }
  })

  return (
    <div>
      {label && (
        <StyledLabel
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Caption size="s" weight={500}>
            {label}
          </Caption>
          {headingComponent}
        </StyledLabel>
      )}
      <StyledAdaptiveImagesBlock
        images={formattedFields}
        imageWidth={56}
        onDelete={!disabled ? remove : undefined}
      />
      {withComment && (
        <InputWithController
          isTextArea
          form={form}
          label="Комментарий"
          name={`${name}.comment`}
          disabled={disabled}
        />
      )}
    </div>
  )
}

ImagesBlock.defaultProps = {
  withComment: true,
}

export default ImagesBlock

const StyledLabel = styled(Grid)`
  margin-bottom: 8px;
`

const StyledAdaptiveImagesBlock = styled(AdaptiveImagesBlock)`
  margin-bottom: 8px;
`
