import React from "react"
import { observer } from "mobx-react"
import { clearIndexStores } from "kui-utils"
import { InspectionTypeButtonProps } from "./types"
import useApartmentStore from "../../../../../hooks/useApartmentStore"
import inspectionTypeLabels from "./content"
import OptionButton from "../../../../../components/common/OptionButton"
import { formFieldsName, formStateName } from "../../../../../utils/constants"

const InspectionTypeButton = (props: InspectionTypeButtonProps) => {
  const { type, disabled, onClick } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm

  const handleClick = () => {
    if (
      (formStore.fields?.type && formStore.fields?.type !== type) ||
      !inspectionsStore.canUpdateDB
    ) {
      formStore.resetForm()
      clearIndexStores(inspectionsStore.indexDB, [
        formStateName,
        formFieldsName,
      ])
    }

    inspectionsStore.setCanUpdateDB(true)
    formStore.updateFormFields({
      type,
      apartmentId: inspectionsStore.apartmentId,
    })
    onClick()
  }

  return (
    <OptionButton
      disabled={disabled}
      label={inspectionTypeLabels[type]}
      value={type}
      onClick={handleClick}
    />
  )
}

export default observer(InspectionTypeButton)
