import React from "react"
import { TableBottomLoading, usePaginationPage } from "kui-crm"
import { Loading } from "kui-complex"
import { observer } from "mobx-react"
import UpcomingInspectionCard from "../UpcomingInspectionCard"
import useStore from "../../../../hooks/useStore"

const UpcomingInspectionsList = () => {
  const { apartmentsPageStore } = useStore()
  const { actionLoader, paginator, fetchAll, filter, apartments } =
    apartmentsPageStore.upcomingInspectionsStore

  usePaginationPage(paginator, fetchAll, filter)

  if (actionLoader.isLoading && paginator.offset < paginator.limit)
    return <Loading height="95vh" />

  return (
    <>
      <div>
        {apartments.map((apartment) => (
          <UpcomingInspectionCard key={apartment.id} apartment={apartment} />
        ))}
      </div>
      <TableBottomLoading
        isLoading={
          actionLoader.isLoading && paginator.offset >= paginator.limit
        }
      />
    </>
  )
}

export default observer(UpcomingInspectionsList)
