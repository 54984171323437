import { getPartialMeterInfoFields } from "kui-crm"
import { PartialMeterInfoFields } from "kui-crm_actions"
import commonInspectionFields from "../../../../pages/ApartmentPage/common/InspectionFirstScreen/content"
import getMeterImagesStep from "../../../../pages/ApartmentPage/common/MeterImagesStep/getMeterImagesStep"

export const getRegularFields = (meters: PartialMeterInfoFields[]) => {
  const metersFields = meters
    .map((meter: any, index: number) => [
      ...getPartialMeterInfoFields(
        meter.resource,
        meter.type,
        meter,
        true,
        `meters.${index}`,
      ),
      getMeterImagesStep(index),
    ])
    .flat()

  return [...commonInspectionFields, ...metersFields]
}
