import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { observer } from "mobx-react"
import { LoaderState, PageBreadcrumbs } from "kui-crm"
import { Container } from "kui-basic"
import styled from "@emotion/styled"
import { useInspectionData, useInspectionIndexDB } from "kui-crm_actions"
import Page from "../../components/common/Page"
import useApartmentStore from "../../hooks/useApartmentStore"
import { EntityParams } from "../../types/common"
import InspectionFirstScreen from "./common/InspectionFirstScreen"
import InspectionSuccessScreen from "./common/InspectionSuccessScreen"
import InspectionForm from "./common/InspectionForm"
import { paths } from "../../routes"
import { inspectionCreationConstants } from "../../utils/constants"

const ApartmentPage = () => {
  const apartmentStore = useApartmentStore()
  const { fetchApartment, loader, resetApartmentPage } = apartmentStore
  const { apartmentInfo, inspectionsStore } = apartmentStore
  const [isCreated, setIsCreated] = useState(false)
  const { id } = useParams<EntityParams>()
  const formStore = inspectionsStore.creationForm
  const navigate = useNavigate()

  const breadcrumbs = [
    { link: paths.apartmentsList, title: "Objects" },
    { link: null, title: apartmentInfo?.address },
  ]

  const handleCreate = () => setIsCreated(true)

  const handlePrevClick = () => {
    navigate(paths.apartmentsList)
  }

  useInspectionIndexDB(inspectionCreationConstants, apartmentStore)

  useInspectionData(apartmentStore)

  useEffect(() => {
    if (id) fetchApartment(Number(id))
    return () => resetApartmentPage()
  }, [])

  if (isCreated) return <InspectionSuccessScreen />

  return (
    <Page loader={loader}>
      <Container>
        <StyledBreadcrumbs onPrev={handlePrevClick} breadcrumbs={breadcrumbs} />
      </Container>
      {formStore.step === 1 ? (
        <InspectionFirstScreen />
      ) : (
        <InspectionForm handleCreate={handleCreate} />
      )}
      <LoaderState loader={inspectionsStore.actionLoader} />
    </Page>
  )
}

export default observer(ApartmentPage)

const StyledBreadcrumbs = styled(PageBreadcrumbs)`
  padding: 16px 0;
`
