import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { ApartmentInspectionParams } from "kui-crm_actions"
import ApartmentsListWithMap from "../ApartmentsListWithMap"
import useStore from "../../../hooks/useStore"
import UpcomingInspectionsList from "./UpcomingInspectionsList"

const UpcomingInspectionsPage = () => {
  const { apartmentsPageStore } = useStore()
  const { apartmentsWithInspection, paginator } =
    apartmentsPageStore.upcomingInspectionsStore

  const getLabel = (inspection: ApartmentInspectionParams) =>
    inspection.date?.toFormat("dd.MM.yyyy") ||
    `${Number(inspection.apartment.roomsCount)}-комн.`

  useEffect(
    () => () => {
      paginator.clearOffset()
    },
    [],
  )

  return (
    <ApartmentsListWithMap
      apartmentsStore={apartmentsPageStore.upcomingInspectionsStore}
      apartmentsForMap={apartmentsWithInspection}
      listComponent={<UpcomingInspectionsList />}
      getLabel={getLabel}
      paginator={paginator}
    />
  )
}

export default observer(UpcomingInspectionsPage)
