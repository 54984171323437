import { meterCheckValueMessage } from "../../../../utils/constants"

const meterLabels = {
  electricity: "электричества",
  water: "воды",
  heating: "отопления",
  gas: "газа",
}

const meterTypes = {
  cold: "холодной",
  hot: "горячей",
  T1: "T1",
  T2: "T2",
  T3: "T3",
}

const partialMeterInfoSettings = {
  number: { label: "Номер" },
  t1: { label: "T1 значение", warningMessage: meterCheckValueMessage },
  t2: { label: "T2 значение", warningMessage: meterCheckValueMessage },
  t3: { label: "T3 значение", warningMessage: meterCheckValueMessage },
  value: { label: "Значение", warningMessage: meterCheckValueMessage },
}

export { meterTypes, partialMeterInfoSettings }
export default meterLabels
