import * as React from "react"
import { observer } from "mobx-react"
import { SignInPage as SignInPageKUI } from "kui-crm"
import useStore from "../../hooks/useStore"

const SignInPage: React.FC = () => {
  const {
    authStore: { signIn, loader },
  } = useStore()

  return (
    <SignInPageKUI
      handleSubmit={signIn}
      err={!!loader.errorMessage}
      isLoading={loader.isLoading}
    />
  )
}

export default observer(SignInPage)
