import React from "react"
import {
  getWaterMeterFields,
  PartialMeterInfoFields,
  WaterMeterFieldsSchema,
} from "kui-crm_actions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../hooks/useApartmentStore"
import FormWrapper from "../../../../components/common/FormWrapper"
import { WaterMeterInfoStepProps } from "./types"
import { waterMeterFieldsSettings } from "./content"

const WaterMeterInfoStep = (props: WaterMeterInfoStepProps) => {
  const { index, withCheckValue } = props
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const label = "Проверьте и введите данные счетчика воды"
  const notFilledMeters =
    formStore.fields?.meters?.filter(
      (meter: PartialMeterInfoFields) =>
        meter.resource === "water" && !meter.value,
    ) || []
  const meter = formStore.fields?.meters?.[index]
  const fields = getWaterMeterFields(
    notFilledMeters,
    meter,
    withCheckValue,
    "",
    waterMeterFieldsSettings,
  )

  const form = useForm({
    values: meter,
    resolver: yupResolver(WaterMeterFieldsSchema),
  })

  const handleSubmit = (data: PartialMeterInfoFields) => {
    formStore.replaceFieldInArray(
      "meters",
      { ...data, resource: "water" },
      index,
    )
    formStore.nextStep()
  }

  return (
    <FormWrapper
      title={label}
      form={form}
      onSubmit={handleSubmit}
      onPrev={formStore.prevStep}
    >
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item key={field.name} xs={12}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(WaterMeterInfoStep)
